import { t } from '../../services/i18n';
import { Ionicons } from '@expo/vector-icons';
import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, StyleSheet, TouchableOpacity, Dimensions, Keyboard, Image, InputAccessoryView, Appearance, Platform } from 'react-native';

interface TextAreaProps {
    navigation?: any;
    route?: any;
}

const TextArea = (props: TextAreaProps) => {
    const inputRef = React.useRef<TextInput>(null);
    const onSuccess = props.route.params?.onSuccess || (() => { props.navigation.navigate("settings"); });
    const onCancel = props.route.params?.onCancel || (() => { props.navigation.navigate("settings"); });
    const title = t('report-a-problem:title');
    const buttonTitle = t('report-a-problem:send');
    const placeholder = t('report-a-problem:placeholder');
    const maxLength = props.route.params?.maxLength || 500;
    const minLength = props.route.params?.minLength || 10;
    const extraData = props.route.params?.extra || {};
    const [text, setText] = useState('');
    const userImage = props.route.params?.userImage || null;
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    const [screenHeight, setScreenHeight] = useState(Dimensions.get('window').height);

    useEffect(() => {
        const openKeyboard = Keyboard.addListener('keyboardDidShow', (e) => {
            setKeyboardHeight(e.endCoordinates.height);
            setScreenHeight(Dimensions.get('window').height - e.endCoordinates.height);
        });
        const hideKeyboard = Keyboard.addListener('keyboardDidHide', () => {
            setKeyboardHeight(0);
            setScreenHeight(Dimensions.get('window').height);
        });

        const subscription = props.navigation.addListener('focus', () => {
            setTimeout(() => {
                inputRef.current.focus()
            }, 200);
        });

        return () => {
            subscription();
            openKeyboard.remove();
            hideKeyboard.remove();
        };
    }, []);

    useEffect(() => {
        setText(text.trimStart().replace('\n\n\n', '\n\n'));
    }, [text]);

    return (
        <>
            <View style={styles.container}>
                <Text style={styles.title}>{title}</Text>
                <TouchableOpacity style={styles.back} onPress={onCancel}>
                    <Ionicons name="close" size={16} color={'#444'} />
                </TouchableOpacity>
                <View style={styles.newCommentView}>
                    <View style={styles.imageView}>
                        {
                            userImage ?
                                <Image source={{ uri: userStore.me.imageUri }} style={styles.profilePicture} />
                                :
                                <Image style={styles.profilePicture} source={require('../../assets/images/ersinayaz.webp')} />
                        }
                    </View>
                    <View style={{ width: "87%" }}>
                        <TextInput ref={inputRef} autoFocus={true} placeholderTextColor={'#C5CACE'} maxLength={maxLength} onChangeText={(text) => setText(text)} value={text} placeholder={placeholder} style={[styles.txtComment, { maxHeight: (screenHeight - 140), minHeight: 40 }, Platform.OS == 'web' ? { outlineStyle: "none", minHeight: 250 } : null]} autoCapitalize="sentences" autoFocus multiline={true} textContentType='none' inputAccessoryViewID='newComment' />
                        <View>
                            <Text style={{ fontSize: 13, color: '#4A4A4A' }}>{t("report-a-problem:characters-remaining")}: <Text style={{ fontWeight: "bold", color: '#4A4A4A' }}>{text.length == 0 ? maxLength : maxLength - text.length}</Text></Text>
                        </View>
                    </View>

                </View>
                {Platform.OS == 'web' ?
                    <TouchableOpacity onPress={() => { onSuccess(text.split(), extraData); }} disabled={text.length < minLength ? true : false} style={[styles.btnNewComment, { backgroundColor: text.length < minLength ? '#C5CACE' : '#5856d7', flexDirection: "row", marginTop: 20 }]} >
                        <Text style={[styles.txtNewComment]}>{buttonTitle}</Text>
                        <View style={[styles.txtNewComment, styles.missingCharView, { display: text.length >= minLength ? "none" : "flex" }]}>
                            <Text style={styles.txtMissingChar}>{text.length < minLength ? (minLength - text.length) : ""}</Text>
                        </View>
                    </TouchableOpacity>
                    :
                    null
                }
            </View>
            {
                Platform.OS != 'web' ?

                    <InputAccessoryView autoFocus={true} nativeID="newComment" style={{ height: 65, padding: 10 }} backgroundColor={Appearance.getColorScheme() == 'dark' ? '#373737' : '#d1d3d9'}>
                        <TouchableOpacity onPress={() => { onSuccess(text.split(), extraData); }} disabled={text.length < minLength ? true : false} style={[styles.btnNewComment, { backgroundColor: text.length < minLength ? '#C5CACE' : '#5856d7', flexDirection: "row" }]} >
                            <Text style={[styles.txtNewComment]}>{buttonTitle}</Text>
                            <View style={[styles.txtNewComment, styles.missingCharView, { display: text.length >= minLength ? "none" : "flex" }]}>
                                <Text style={styles.txtMissingChar}>{text.length < minLength ? (minLength - text.length) : ""}</Text>
                            </View>
                        </TouchableOpacity>
                    </InputAccessoryView>
                    :
                    null
            }
        </>
    );
};

export default TextArea;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
        backgroundColor: '#ffffff',
    },
    title: {
        fontSize: 18,
        color: '#4A4A4A',
        fontWeight: "bold"
    },
    newCommentView: {
        flexDirection: "row",
    },
    imageView: {
        width: 60,
        marginTop: 24
    },
    profilePicture: {
        width: 46,
        height: 46,
        borderRadius: 23,
        backgroundColor: '#EFF2F5'
    },
    btnNewComment: {
        height: 47.5,
        maxWidth: "100%",
        borderRadius: 12.5,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: '#4397F7',
    },
    txtNewComment: {
        fontSize: 15,
        fontWeight: "500",
        textAlign: "center",
        color: '#ffffff',
    },
    back: {
        top: 16,
        right: 16,
        width: 36,
        zIndex: 2,
        height: 36,
        padding: 5,
        borderRadius: 36,
        alignItems: "center",
        position: "absolute",
        justifyContent: "center",
        backgroundColor: '#EFF2F5',
    },
    txtComment: {
        width: "95%",
        marginTop: 20,
        paddingLeft: 0,
        paddingBottom: 10,
        marginBottom: 10,
        color: '#4A4A4A',
        textAlignVertical: "bottom"
    },
    missingCharView: {
        right: 10,
        opacity: .5,
        width: 27.5,
        padding: 7.5,
        height: 27.5,
        borderRadius: 27.5,
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: '#EFF2F5'
    },
    txtMissingChar: {
        fontSize: 11,
        fontWeight: "500",
    },
    parityIconView: {
        left: 10,
        position: "absolute",
    },
    parityIcon: {
        width: 27.5,
        height: 27.5,
        borderRadius: 27.5
    }
});