import * as React from 'react';
import { t } from '../services/i18n';
import * as Clipboard from 'expo-clipboard';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Text, View, StyleSheet, Image, TouchableOpacity, ImageBackground, Alert, ToastAndroid, Platform, ActivityIndicator } from 'react-native';

interface NumberItemProps {
    navigation?: any;
    route?: any;
    item: any;
    index: number;
}

const NumberItem = (props: NumberItemProps) => {
    const [code, setCode] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [codeReceived, setCodeReceived] = React.useState<boolean>(false);

    const clipboard = async (type, str) => {
        switch (Platform.OS) {
            case "ios":
                Alert.alert(t(type == 1 ? "numbers:item:copied:phone" : "numbers:item:copied:code"));
                break;
            case "android":
                ToastAndroid.show(t(type == 1 ? "numbers:item:copied:phone" : "numbers:item:copied:code"), ToastAndroid.SHORT);
                break;
            default:
                alert(t(type == 1 ? "numbers:item:copied:phone" : "numbers:item:copied:code"));
                break;
        }
        Clipboard.setStringAsync(str.replace(/-/g, ''));
    };

    return (
        <View style={[styles.container, { backgroundColor: props.index % 2 == 0 ? '#ffffff90' : '#fff' }]}>
            <View style={{ flexDirection: "row" }}>
                <View style={styles.left}>
                    <Image source={props.item.service.style.image} style={styles.imgService} />
                    <Image source={props.item.country.image} style={styles.imgCountry} />
                </View>
                <View style={styles.right}>
                    <View>
                        <Text style={styles.phoneNumber}>{props.item.phoneNumber}</Text>
                        <Text style={styles.countryName}>{props.item.country.name}</Text>
                    </View>
                    <View>
                        <TouchableOpacity onPress={() => clipboard(1, props.item.phoneNumber)}>
                            <MaterialCommunityIcons name="content-copy" size={28} color="#333333" />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <View style={[styles.right, { marginLeft: 80, marginTop: 5 }]}>
                <TouchableOpacity style={styles.codeView} onPress={() => clipboard(2, props.item.code)}>
                    <Text style={styles.txtVerificationCode}>{t('numbers:item:verification_code')}:</Text>
                    {
                        !props.item.code ? <ActivityIndicator size="small" color="#333" />
                            :
                            <Text style={styles.txtCode}>{props.item.code}</Text>
                    }
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default NumberItem;

const styles = StyleSheet.create({
    container: {
        padding: 16,
        width: '100%',
        flexDirection: 'column'
    },
    left: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    right: {
        flex: 1,
        marginLeft: 16,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    imgService: {
        width: 64,
        height: 64,
        borderRadius: 32,
        borderWidth: 1.5,
        borderColor: "#D8D8D8"
    },
    imgCountry: {
        width: 24,
        height: 24,
        borderRadius: 12,
        borderWidth: 1,
        borderColor: "#FFFFFF",
        position: 'absolute',
        bottom: -2.5,
        right: -2.5,
    },
    phoneNumber: {
        fontSize: 16,
        color: '#333333',
        fontFamily: 'SFProDisplay-Bold',
    },
    countryName: {
        fontSize: 13,
        marginTop: 2,
        color: '#666666',
        fontFamily: 'SFProDisplay-Medium',
    },
    codeView: {
        flex: 1,
        padding: 12,
        borderWidth: 1,
        borderRadius: 8,
        flexDirection: "row",
        alignItems: "center",
        borderColor: "#337fff",
        backgroundColor: "#ebf2ff",
        justifyContent: "space-between"
    },
    txtVerificationCode: {
        color: '#337fff',
        fontFamily: 'SFProDisplay-Medium',
    },
    txtCode: {
        fontSize: 16,
        color: '#337fff',
        fontFamily: 'SFProDisplay-Bold',
    },
});
