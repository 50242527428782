import * as React from 'react';
import { useStore } from '../store';
import { t } from '../services/i18n';
import Header from '../components/Header/Purchase';
import Purchases, { PURCHASE_TYPE } from 'react-native-purchases';
import { Text, View, StyleSheet, SafeAreaView, Image, TouchableOpacity, Platform, Dimensions, ActivityIndicator, Alert } from 'react-native';

interface PurchaseProps {
    navigation: any;
    route: any;
}

const Purchase = (props: PurchaseProps) => {
    const { serviceStore } = useStore();
    const service = props.route.params?.service;
    const country = props.route.params?.country;
    const [product, setProduct] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        props.navigation.setOptions({
            title: service.name + " - " + country.name,
        });

        if (Platform.OS != 'web') {
            Purchases.getProducts([country.productId], PURCHASE_TYPE.SUBS).then((products) => {
                setProduct(products[0]);
            }
            ).catch((e) => {
                console.log(JSON.stringify(e));
            });
        }
        else {
            setProduct({
                identifier: country.productId,
                priceString: '₺ 129,99'
            });
        }
    }, []);

    const infoCard = (image, text) => {
        return (
            <View style={styles.infoCard}>
                <View style={styles.imageView}>
                    {image}
                </View>
                <View style={{ flex: 1 }}>
                    {text}
                </View>
            </View>
        );
    }

    const restore = async () => {
        setLoading(true);
        Purchases.restorePurchases().then((customerInfo) => {
            setLoading(false);
            if (customerInfo.activeSubscriptions.length > 0) {
                // check activeSubscriptions by productId
                if (customerInfo.activeSubscriptions.filter((item) => item == country.productId).length > 0) {
                    props.navigation.reset({
                        index: 0,
                        routes: [{ name: 'numbers' }],
                    });
                }
                else {
                    Alert.alert(t('purchase:information'), t('revenuecat:error:PURCHASE_RESTORE_NOT_FOUND'));
                }
            }
            else {
                Alert.alert(t('purchase:information'), t('revenuecat:error:PURCHASE_RESTORE_NOT_FOUND'));
            }
        }).catch((e) => { errorHandler(e); });
    }

    const errorHandler = (e) => {
        setLoading(false);
        switch (e.code) {
            case Purchases.PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR: break;
            case Purchases.PURCHASES_ERROR_CODE.MISSING_RECEIPT_FILE_ERROR:
                Alert.alert(t('purchase:information'), t('revenuecat:error:MISSING_RECEIPT_FILE_ERROR'));
                break;
            case Purchases.PURCHASES_ERROR_CODE.NETWORK_ERROR:
                Alert.alert(t('purchase:information'), t('revenuecat:error:NETWORK_ERROR'));
                break;
            case Purchases.PURCHASES_ERROR_CODE.STORE_PROBLEM_ERROR:
                Alert.alert(t('purchase:information'), t('revenuecat:error:STORE_PROBLEM_ERROR').replace('{0}', Platform.select({ ios: 'App Store', android: 'Google Play' })));
                break;
            case Purchases.PURCHASES_ERROR_CODE.PRODUCT_ALREADY_PURCHASED:
                Alert.alert(t('purchase:information'), t('revenuecat:error:PRODUCT_ALREADY_PURCHASED'));
                break;
            case Purchases.PURCHASES_ERROR_CODE.PURCHASE_NOT_ALLOWED:
                Alert.alert(t('purchase:information'), t('revenuecat:error:PURCHASE_NOT_ALLOWED'));
                break;
            default:
                console.log(JSON.stringify(e));
                Alert.alert(t('purchase:information'), t('revenuecat:error:default'));
                break;
        }
    }

    const buy = async () => {
        setLoading(true);
        if (Platform.OS == 'web') {
            buyForWeb();
            return;
        }

        try {
            const purchase = await Purchases.purchaseProduct(product.identifier, null, PURCHASE_TYPE.SUBS);
            if (purchase.productIdentifier == product.identifier) {
                props.navigation.reset({
                    index: 0,
                    routes: [{ name: 'numbers', params: { identifier: product.identifier } }],
                });
            }
        } catch (e) {
            errorHandler(e);
        }
        finally {
            setLoading(false);
        }
    }

    const buyForWeb = async () => {
        setTimeout(() => {
            props.navigation.reset({
                index: 0,
                routes: [{ name: 'numbers' }],
            });
        }, 1500);
    }

    return (
        <SafeAreaView style={{ backgroundColor: service.style.background[0], flex: 1 }}>
            {loading ?
                <View style={styles.loading}>
                    <ActivityIndicator size="large" color="#666" />
                </View>
                :
                null
            }
            <Header navigation={props.navigation} route={props.route} service={service} country={country} />
            <View style={styles.container}>
                <Text style={styles.txtInformation}>{t('purchase:information')}</Text>

                {infoCard(<Image source={require('../assets/images/sana-ozel.webp')} style={{ width: 23, height: 23 }} />, <Text style={styles.txtInfoCard}>{t('purchase:info:1')}</Text>)}
                {infoCard(<Image source={require('../assets/images/dogrulama.webp')} style={{ width: 26, height: 26 }} />, <Text style={styles.txtInfoCard}>{t('purchase:info:2')}</Text>)}
                {infoCard(<Image source={require('../assets/images/cagri.webp')} style={{ width: 22, height: 22 }} />, <Text style={styles.txtInfoCard}>{t('purchase:info:3')}</Text>)}
                {infoCard(<Image source={require('../assets/images/numara-gorunme.webp')} style={{ width: 28, height: 18 }} />, <Text style={styles.txtInfoCard}>{t('purchase:info:4')}</Text>)}

                <View style={{ flex: 1, width: "100%", justifyContent: "flex-start" }}>
                    <TouchableOpacity onPress={() => { buy(); }} style={[styles.btnPurchase, { backgroundColor: service.style.background[0] }]}>
                        <View style={[styles.btnPurchaseItem, styles.btnPurchaseItemLeft, { backgroundColor: service.style.background[0] }]}>
                            <Text style={styles.txtStartUsing}>{t('purchase:start-using')}</Text>
                        </View>
                        <View style={[styles.btnPurchaseItem, styles.btnPurchaseItemRight, { backgroundColor: service.style.background[1] }]}>
                            {product?.priceString ? <Text style={styles.txtPrice}>{product?.priceString} / ay</Text>
                                :
                                <ActivityIndicator size="small" color="#ffffff" />
                            }
                        </View>
                    </TouchableOpacity>
                    {Platform.OS != "web" ?
                        <Text style={styles.txtSubscriptionDescription}>{t('purchase:subscription-description').replace('{0}', Platform.OS == "ios" ? "iTunes" : "Google Play")}</Text>
                        :
                        null
                    }

                    <View style={styles.footer}>
                        <TouchableOpacity onPress={() => props.navigation.navigate('policy', { type: 'privacy' })}>
                            <Text style={styles.footerItem}>{t('settings:sections:general:privacy_policy')}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => props.navigation.navigate('policy', { type: 'terms-and-conditions' })}>
                            <Text style={styles.footerItem}>{t('settings:sections:general:terms_and_conditions')}</Text>
                        </TouchableOpacity>
                        {
                            Platform.OS == "web" ?
                                null
                                :
                                <TouchableOpacity onPress={() => { restore(); }}>
                                    <Text style={styles.footerItem}>{t("purchase:restore")}</Text>
                                </TouchableOpacity>
                        }
                    </View>
                </View>
            </View>
        </SafeAreaView>
    );
};

export default Purchase;

const styles = StyleSheet.create({
    container: {
        width: "100%",
        paddingTop: 50,
        paddingHorizontal: 16,
        alignItems: 'flex-start',
        backgroundColor: '#f8f9fc',
        justifyContent: 'flex-start',
        height: Dimensions.get('window').height - 180
    },
    txtInformation: {
        fontSize: 20,
        marginBottom: 16,
        color: '#333333',
        fontFamily: 'SFProDisplay-Bold'
    },
    infoCard: {
        height: 55,
        padding: 16,
        width: "100%",
        borderWidth: .5,
        marginBottom: 11,
        borderRadius: 7.5,
        flexDirection: "row",
        alignItems: "center",
        borderColor: "#E6E6E6",
        backgroundColor: "#ffffff"
    },
    imageView: {
        width: 35
    },
    txtInfoCard: {
        marginLeft: 5,
        color: "#444444",
        fontFamily: 'SFProDisplay-Medium',
    },
    btnPurchase: {
        height: 60,
        padding: 4,
        width: "100%",
        marginBottom: 16,
        marginTop: 10,
        borderRadius: 16,
        flexDirection: "row",
    },
    btnPurchaseItem: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",

    },
    txtStartUsing: {
        fontSize: 16,
        color: "#fff",
        fontFamily: 'SFProDisplay-Bold',
    },
    txtPrice: {
        fontSize: 15,
        color: "#ffffff",
        fontFamily: 'SFProDisplay-Bold'
    },
    btnPurchaseItemLeft: {
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16
    },
    btnPurchaseItemRight: {
        borderRadius: 16,
    },
    txtSubscriptionDescription: {
        padding: 4,
        fontSize: 10,
        marginBottom: 16,
        color: "#9B9B9B",
        textAlign: "justify",
        fontFamily: 'SFProDisplay-Regular'
    },
    footer: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-around",
    },
    footerItem: {
        fontSize: 13,
        color: "#666666",
        fontFamily: 'SFProDisplay-Medium'
    },
    loading: {
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 99999,
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#ffffff99",
    }
});