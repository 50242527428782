import { FontAwesome5 } from '@expo/vector-icons';
import { SafeAreaView } from 'react-native-safe-area-context';
import { View, StyleSheet, Image, TouchableOpacity, Platform, Text } from 'react-native';


interface HeaderProps {
    navigation?: any;
    route?: any;
    count?: number;
}

const Header = (props: HeaderProps) => {

    const goSettings = () => {
        props.navigation.navigate("settings");
    }

    const goNumbers = () => {
        props.navigation.navigate("numbers");
    }

    const noticationBubble = () => {
        return (
            <View style={styles.notificationBubble}>
                <Text style={styles.notificationBubbleText}>{props.count}</Text>
            </View>
        );
    }

    return (
        <>
        <SafeAreaView edges={["top"]} style={{ flex: 0, backgroundColor: "#fff" }} />
        <View style={styles.container}>
            <View style={styles.header}>
                <Image source={require('../../assets/images/logo.webp')} style={styles.logo} />
                <View style={styles.buttons}>
                    <TouchableOpacity style={styles.button} onPress={goNumbers}>
                        <FontAwesome5 name="sim-card" size={22} color="#5856d7" />
                        {props.count > 0 ? noticationBubble() : null}
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button} onPress={goSettings}>
                        <FontAwesome5 name="cog" size={22} color="#5856d7" />
                    </TouchableOpacity>
                </View>
            </View>
        </View>
        </>
    );
};

export default Header;

const styles = StyleSheet.create({
    container: {
        flex: 0,
        padding: 16,
        paddingTop: 10,
        backgroundColor: '#fff',
        borderBottomColor: '#e6e6e6',
        borderBottomWidth: .5,
        minHeight: Platform.OS === 'web' ? 70 : 50,
    },
    header: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    logo: {
        width: 100,
        height: 33,
        tintColor: '#5856d7',
        resizeMode: 'contain'
    },
    buttons: {
        flexDirection: 'row',
    },
    button: {
        width: 40,
        height: 40,
        // borderWidth: 2,
        marginLeft: 10,
        borderRadius: 15,
        alignItems: 'center',
        // borderColor: '#eeeeee50',
        justifyContent: 'center',
        backgroundColor: '#f8f9fc'
    },
    notificationBubble: {
        position: 'absolute',
        top: -5,
        right: -5,
        zIndex: 1,
        width: 20,
        height: 20,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#5856d7'
    },
    notificationBubbleText: {
        color: '#ffffff',
        fontSize: 12,
        fontWeight: 'bold'
    }
});
