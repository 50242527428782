import * as React from 'react';
import { t } from '../services/i18n';
import packageJson from '../../app.json';
import Header from '../components/Header/Settings';
import { FontAwesome5, Ionicons, Feather } from '@expo/vector-icons';
import { Text, View, StyleSheet, SectionList, TouchableOpacity, Image, Linking, Platform, Share, Alert } from 'react-native';

interface SettingsProps {
  navigation?: any;
  route?: any;
}

const Settings = (props: SettingsProps) => {
  const [data, setData] = React.useState<any>([
    // {
    //   id: 1,
    //   title: t('settings:sections:account:title'),
    //   data: [
    //     {
    //       id: 1,
    //       title: t('settings:sections:account:profile'),
    //       icon: "user",
    //       action: {
    //         type: "navigation",
    //         screenName: "profile"
    //       }
    //     },
    //     {
    //       id: 2,
    //       title: t('settings:sections:account:account_settings'),
    //       icon: "settings",
    //       action: {
    //         type: "navigation",
    //         screenName: "account-settings"
    //       }
    //     },
    //     {
    //       id: 3,
    //       title: t('settings:sections:account:logout'),
    //       icon: "log-out",
    //       action: {
    //         type: "action",
    //         actionName: "logout"
    //       }
    //     }
    //   ]
    // },
    {
      id: 2,
      title: t('settings:sections:general:title'),
      data: [
        {
          id: 1,
          title: t('settings:sections:general:privacy_policy'),
          icon: "eye",
          action: {
            type: "navigation",
            screenName: "policy",
            extra: { type: "privacy" }
          }
        },
        {
          id: 2,
          title: t('settings:sections:general:terms_and_conditions'),
          icon: "file-text",
          action: {
            type: "navigation",
            screenName: "policy",
            extra: { type: "terms-and-conditions" }
          }
        },
        {
          id: 3,
          title: t('settings:sections:general:rate_us'),
          icon: "thumbs-up",
          action: {
            type: "action",
            actionName: "rate-us"
          }
        },
        {
          id: 4,
          title: t('settings:sections:general:share_app'),
          icon: "share",
          action: {
            type: "action",
            actionName: "share-app"
          }
        }
      ]
    },
    {
      id: 3,
      title: t('settings:sections:support:title'),
      data: [
        {
          id: 1,
          title: t('settings:sections:support:about_us'),
          icon: "info",
          action: {
            type: "navigation",
            screenName: "about-us"
          }
        },
        {
          id: 2,
          title: t('settings:sections:support:faq'),
          icon: "help-circle",
          action: {
            type: "navigation",
            screenName: "faq"
          }
        },
        {
          id: 3,
          title: t('settings:sections:support:report_a_problem'),
          icon: "alert-triangle",
          action: {
            type: "navigation",
            screenName: "report-a-problem"
          }
        },
        {
          id: 4,
          title: t('settings:sections:support:visit_our_website'),
          icon: "globe",
          action: {
            type: "action",
            actionName: "website"
          }
        }
      ]
    }
  ]);

  const [profile, setProfile] = React.useState<any>({
    name: "Ersin Ayaz",
    email: "ersinayaz@gmail.com",
    image: require('../assets/images/ersinayaz.webp')
  });

  const itemClick = async (item: any) => {
    switch (item.action.type) {
      case 'navigation':
        props.navigation.navigate(item.action.screenName, item.action.extra); break;
      case 'action':
        switch (item.action.actionName) {
          case 'logout':
            break;
          case 'rate-us':
            Linking.openURL(Platform.select({ ios: `itms-apps://apps.apple.com/app/id1545685730?action=write-review`, android: `market://details?id=and.iverify.app`, web: `https://apps.apple.com/app/id1545685730?action=write-review` }));
            break;
          case 'share-app':
            Share.share({
              title: packageJson.expo.name,
              message: packageJson.expo.name + '\n' + packageJson.expo.description + '\n\n',
              url: Platform.OS === 'android' ? 'https://play.google.com/store/apps/details?id=com.ersinayaz.i-verify' : 'https://apps.apple.com/tr/app/i-verify/id1545685730?l=tr',
            });
            break;
          case 'website':
            Linking.openURL('https://www.i-verify.app/');
            break;
          case 'report-a-problem':
            props.navigation.navigate('report-a-problem');
            // if (Platform.OS === 'web') {
            //   props.navigation.navigate('report-a-problem');
            //   return;
            // }
            // else {
            //   Alert.alert(t('report-a-problem:alert:title'), t('report-a-problem:alert:description'), [
            //     {
            //       text: t('report-a-problem:alert:cancel'),
            //       style: 'cancel',
            //     },
            //     {
            //       text: t('report-a-problem:alert:ok'),
            //       onPress: () => { props.navigation.navigate('faq'); }
            //     },
            //     {
            //       text: t('report-a-problem:alert:report'),
            //       onPress: async () => { 
            //         props.navigation.navigate('report-a-problem');
            //       },
            //       style: 'destructive',
            //     }
            //   ]);
            // }
            break;
        }
        break;
    }
  };

  const renderItem = ({ item, index, section }) => {
    return (
      <TouchableOpacity style={styles.item} onPress={() => itemClick(item)}>
        <View style={{ flex: 1, flexDirection: "row" }}>
          <Feather name={item.icon} size={16} color="#444" />
          <Text style={styles.title}>{item.title}</Text>
        </View>
        <Feather name="chevron-right" size={16} color="#444" />
      </TouchableOpacity>
    );
  };

  return (
    <>
      <Header returnPage='home' route={props.route} navigation={props.navigation} title={t('settings:header:title')} subtitle='' description={t('settings:header:subtitle')} />
      <View style={styles.container}>
        {/* <View style={styles.profileCard}>
          <Image source={profile.image} style={styles.profileImage} />
          <View style={styles.profileInfo}>
            <Text style={styles.profileName}>{profile.name}</Text>
            <Text style={styles.profileEmail}>{profile.email}</Text>
          </View>
        </View> */}
        <SectionList
          sections={data}
          style={{ flex: 1, marginTop: 16 }}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item, index) => item + index}
          renderSectionFooter={() => (<View style={{ height: 16 }} />)}
          renderItem={({ item, index, section }) => renderItem({ item, index, section })}
          renderSectionHeader={({ section: { id, title } }) => (<Text style={[styles.sectionHeader]}>{title}</Text>)}
        />
        <Text style={styles.footer}>Version: <Text style={styles.versionCode}>{packageJson.expo.version}</Text></Text>
      </View>
    </>
  );
};

export default Settings;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: '#f8f9fc',
  },
  sectionHeader: {
    fontSize: 16,
    color: '#333333',
    paddingBottom: 6,
    backgroundColor: '#f8f9fc',
    fontFamily: "SFProDisplay-Bold"
  },
  item: {
    borderRadius: 8,
    marginVertical: 4,
    paddingVertical: 19,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 16,
    backgroundColor: '#ffffff',
    justifyContent: "space-between"
  },
  title: {
    fontSize: 13,
    marginLeft: 16,
    color: "#666666",
    fontFamily: "SFProDisplay-Medium",
  },
  profileCard: {
    marginTop: 16,
    marginBottom: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  profileImage: {
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: "#fff",
  },
  profileInfo: {
    flex: 1,
    left: 16
  },
  profileName: {
    fontSize: 16,
    color: "#333333",
    fontFamily: "SFProDisplay-Bold"
  },
  profileEmail: {
    fontSize: 12,
    color: "#333333",
    fontFamily: "SFProDisplay-Regular"
  },
  footer: {
    margin: 16,
    fontSize: 12,
    color: "#333333",
    textAlign: "center",
    fontFamily: "SFProDisplay-Regular"
  },
  versionCode: {
    fontFamily: "SFProDisplay-Bold"
  }
});