import { t } from '../services/i18n';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { observable, action, makeAutoObservable, runInAction } from 'mobx';

export class ServiceStore {
  @observable services: any = [];

  constructor() {
    makeAutoObservable(this, {
      services: observable,
      setServices: action,
    });
  }

  @action async setServices(entities: any) {
    await AsyncStorage.setItem('services', JSON.stringify(entities));
    runInAction(() => {
      this.services = entities;
    });
  }
}

export default new ServiceStore();