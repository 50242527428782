import AsyncStorage from '@react-native-async-storage/async-storage';
import { observable, action, makeAutoObservable, runInAction } from 'mobx';

export class UserStore {
    @observable me?: any;
    @observable purchases: any[] = [];

    constructor() {
        makeAutoObservable(this, {
            me: observable,
            purchases: observable,
            setUser: action,
            initUser: action,
            logout: action,
            setPurchases: action,
        });

        this.initUser();
    }

    @action async initUser() {
        const user = await AsyncStorage.getItem('user');
        runInAction(() => {
            this.me = user ? JSON.parse(user) : undefined;
        })
    }

    @action async setUser(user: any) {
        await AsyncStorage.setItem('user', JSON.stringify(user));
        runInAction(() => {
            this.me = user;
        });
    }

    @action async logout() {
        await AsyncStorage.removeItem('user');
        runInAction(() => {
            this.me = undefined;
        });
    }

    @action async setPurchases(purchases: any[]) {
        runInAction(() => {
            this.purchases = purchases;
        });
    }
}

export default new UserStore();