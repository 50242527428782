import * as React from 'react';
import { reaction } from 'mobx';
import { useStore } from '../store';
import { t } from '../services/i18n';
import { db } from '../config/firebase';
import Header from '../components/Header/Home';
import { SafeAreaView } from 'react-native-safe-area-context';
import { collection, getDocs, onSnapshot, doc } from 'firebase/firestore';
import { Service, Countries, HighPriceServices } from '../assets/data/enums';
import { Text, View, StyleSheet, SectionList, FlatList, Image, TouchableOpacity, ActivityIndicator } from 'react-native';

interface HomeProps {
  navigation?: any;
  route?: any;
}

const Home = (props: HomeProps) => {
  const [data, setData] = React.useState([]);
  const [purchaseCount, setPurchaseCount] = React.useState(0);
  const { serviceStore, userStore, deviceStore } = useStore();
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {

    const setDumyData = async () => {
      const dummyData = [
        {
          title: t('common:categories:1'),
          data: [
            [
              {
                id: "wa",
                name: "WhatsApp",
                o: 0,
                style: { color: "#4FCE5D", fontName: "Ionicons", iconName: "logo-whatsapp", image: require('../assets/images/services/whatsapp.webp'), imageLarge: require('../assets/images/services/large/whatsapp.webp'), background: ["#03D15D", "#03B250"] },
                countries: []
              },
              {
                id: "te",
                name: "Telegram",
                o: 1,
                style: { color: "#0088CC", fontName: "Fontisto", iconName: "telegram", image: require('../assets/images/services/telegram.webp'), imageLarge: require('../assets/images/services/large/telegram.webp'), background: ["#34AADF", "#2C6CD8"] },
                countries: []
              },
              {
                id: "li",
                name: "Line",
                o: 2,
                style: { color: "#1EC32E", fontName: "Fontisto", iconName: "line", image: require('../assets/images/services/line.webp'), imageLarge: require('../assets/images/services/large/line.webp'), background: ["#3ACE01", "#31AD01"] },
                countries: []
              },
              {
                id: "vi",
                name: "Viber",
                o: 3,
                style: { color: "#6E4ED2", fontName: "Fontisto", iconName: "viber", image: require('../assets/images/services/viber.webp'), imageLarge: require('../assets/images/services/large/viber.webp'), background: ["#754A91", "#5E3777"] },
                countries: []
              },
              {
                id: "ta",
                name: "Tango",
                o: 4,
                style: { color: "#5E21CF", fontName: "Ionicons", iconName: "logo-yahoo", image: require('../assets/images/services/tango.webp'), imageLarge: require('../assets/images/services/large/tango.webp'), background: ["#FFAD00", "#FF2581"] },
                countries: []
              },
              {
                id: "ve",
                name: "WeChat",
                o: 5,
                style: { color: "#5E21CF", fontName: "Ionicons", iconName: "logo-yahoo", image: require('../assets/images/services/wechat.webp'), imageLarge: require('../assets/images/services/large/wechat.webp'), background: ["#51C332", "#43A429"] },
                countries: []
              },
              {
                id: "si",
                name: "Signal",
                o: 6,
                style: { color: "#3872E9", fontName: "Ionicons", iconName: "ios-chatbubble", image: require('../assets/images/services/signal.webp'), imageLarge: require('../assets/images/services/large/signal.webp'), background: ["#3A76F0", "#3A76F0"] },
                countries: []
              }
            ]
          ]
        },
        {
          title: t('common:categories:2'),
          data: [
            [
              {
                id: "in",
                name: "Instagram",
                o: 7,
                style: { color: "#FB3958", fontName: "Ionicons", iconName: "logo-instagram", image: require('../assets/images/services/instagram.webp'), imageLarge: require('../assets/images/services/large/instagram.webp'), background: ["#FF0051", "#8C3BD0"] },
                countries: []
              },
              {
                id: "tw",
                name: "Twitter",
                o: 8,
                style: { color: "#1DA1F2", fontName: "Ionicons", iconName: "logo-twitter", image: require('../assets/images/services/twitter.webp'), imageLarge: require('../assets/images/services/large/twitter.webp'), background: ["#33CCFF", "#2C6CD8"] },
                countries: []
              },
              {
                id: "fa",
                name: "Facebook",
                o: 9,
                style: { color: "#4267B2", fontName: "Ionicons", iconName: "logo-facebook", image: require('../assets/images/services/facebook.webp'), imageLarge: require('../assets/images/services/large/facebook.webp'), background: ["#337FFF", "#2C6CD8"] },
                countries: []
              },
              {
                id: "tt",
                name: "Tiktok",
                o: 10,
                style: { color: "#5E21CF", fontName: "Ionicons", iconName: "logo-yahoo", image: require('../assets/images/services/tiktok.webp'), imageLarge: require('../assets/images/services/large/tiktok.webp'), background: ["#000000", "#000000"] },
                countries: []
              },
              {
                id: "sc",
                name: "Snapchat",
                o: 11,
                style: { color: "#F7F400", fontName: "Ionicons", iconName: "logo-snapchat", image: require('../assets/images/services/snapchat.webp'), imageLarge: require('../assets/images/services/large/snapchat.webp'), background: ["#FFF853", "#FFF853"] },
                countries: []
              }
            ]
          ]
        },
        {
          title: t('common:categories:3'),
          data: [
            [
              {
                id: "yo",
                name: "Youtube",
                o: 12,
                style: { color: "#FF0000", fontName: "Ionicons", iconName: "logo-youtube", image: require('../assets/images/services/youtube.webp'), imageLarge: require('../assets/images/services/large/youtube.webp'), background: ["#FF0000", "#B22D2D"] },
                countries: []
              },
              {
                id: "ne",
                name: "Netflix",
                o: 13,
                style: { color: "#E50914", fontName: "Fontisto", iconName: "netflix", image: require('../assets/images/services/netflix.webp'), imageLarge: require('../assets/images/services/large/netflix.webp'), background: ["#000000", "#111"] },
                countries: []
              },
              {
                id: "am",
                name: "Amazon",
                o: 14,
                style: { color: "#000000", fontName: "Fontisto", iconName: "amazon", image: require('../assets/images/services/amazon.webp'), imageLarge: require('../assets/images/services/large/amazon.webp'), background: ["#000000", "#3D3D3D"] },
                countries: []
              }
            ]
          ]
        },
        {
          title: t('common:categories:4'),
          data: [
            [
              {
                id: "ti",
                name: "Tinder",
                o: 15,
                style: { color: "#FE3C72", fontName: "Fontisto", iconName: "tinder", image: require('../assets/images/services/tinder.webp'), imageLarge: require('../assets/images/services/large/tinder.webp'), background: ["#EF8C52", "#CE5184"] },
                countries: []
              },
              {
                id: "gr",
                name: "Grindr",
                "description": "Grindr",
                o: 16,
                style: { color: "#F5B849", fontName: "Entypo", iconName: "mask", image: require('../assets/images/services/grindr.webp'), imageLarge: require('../assets/images/services/large/grindr.webp'), background: ["#000000", "#000000"] },
                countries: []
              }
            ]
          ]
        },
        {
          title: t('common:categories:5'),
          data: [
            [
              {
                id: "ya",
                name: "Yahoo",
                o: 17,
                style: { color: "#5E21CF", fontName: "Ionicons", iconName: "logo-yahoo", image: require('../assets/images/services/yahoo.webp'), imageLarge: require('../assets/images/services/large/yahoo.webp'), background: ["#6001D1", "#6001D1"] },
                countries: []
              },
              {
                id: "pa",
                name: "PayPal",
                o: 18,
                style: { color: "#002F83", fontName: "Fontisto", iconName: "paypal-p", image: require('../assets/images/services/paypal.webp'), imageLarge: require('../assets/images/services/large/paypal.webp'), background: ["#009BD9", "#2C6CD8"] },
                countries: []
              },
              {
                id: "ub",
                name: "Uber",
                o: 19,
                style: { color: "#000000", fontName: "Fontisto", iconName: "uber", image: require('../assets/images/services/uber.webp'), imageLarge: require('../assets/images/services/large/uber.webp'), background: ["#000000", "#000000"] },
                countries: []
              },
              {
                id: "ly",
                name: "Lyft",
                o: 20,
                style: { color: "#5E21CF", fontName: "Ionicons", iconName: "logo-yahoo", image: require('../assets/images/services/lyft.webp'), imageLarge: require('../assets/images/services/large/lyft.webp'), background: ["#EA0B8C", "#C71680"] },
                countries: []
              }
            ]
          ]
        }
      ];
      await serviceStore.setServices(dummyData);
      setData(serviceStore.services);
      await getLiveData();
    }

    const getLiveData = async () => {
      getDocs(collection(db, 'Services')).then((snapshot) => {
        const docs = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const mappedData = docs.map((doc) => {
          return {
            id: doc.id,
            name: Service[doc.id].name,
            description: Service[doc.id].description,
            category: Service[doc.id].category,
            o: doc.o,
            style: {
              color: Service[doc.id].style.color,
              fontName: Service[doc.id].style.font,
              iconName: Service[doc.id].style.icon,
              image: Service[doc.id].style.image,
              imageLarge: Service[doc.id].style.imageLarge,
              background: Service[doc.id].style.background,
            },
            countries: doc.c.map((item) => {
              if (HighPriceServices.indexOf(`sbs_${doc.id}_${item.cc}`) == -1) {
                return {
                  code: item.cc,
                  availableCount: item.ac,
                  name: t(`common:countries:${item.cc}`),
                  productId: `sbs_${doc.id}_${item.cc}`,
                  areaCode: Countries[item.cc].areaCode,
                  image: Countries[item.cc].image,
                }
              }
            }).filter(a => a != null).sort((a, b) => a.name > b.name)
          }
        });
        const reducedData = mappedData.reduce((acc, item) => {
          if (!acc[item.category]) {
            acc[item.category] = [];
          }
          acc[item.category].push(item);
          return acc;
        }, {});
        const sectionListData = Object.keys(reducedData).map((key) => {

          return {
            title: t(`common:categories:${key}`),
            data: [reducedData[key].sort((a, b) => a.o - b.o)],
          };
        });

        serviceStore.setServices(sectionListData).then(() => {
          setData(serviceStore.services);
        });
      });
    }

    const userPurchases = async () => {
      const listenPurchases = onSnapshot(doc(collection(db, `users`), deviceStore.deviceId), (snapshot) => {
        if (snapshot.data().purchases) {
          let purchases = snapshot.data().purchases.map((item: any) => {
            let entity = {
              ...item,
              service: Service[item.productId.split('_')[1]],
              country: Countries[item.productId.split('_')[2]]
            }
            entity.country.name = t(`common:countries:${item.productId.split('_')[2]}`);
            return entity;
          });

          userStore.setPurchases(purchases);
        }
      });

      return () => listenPurchases();
    }

    setDumyData().finally(() => {
      setLoaded(true);
    });

    userPurchases();
  }, []);

  React.useEffect(() => {
    const reaction1 = reaction(() => userStore.purchases, (purchases, prev) => {
      if (purchases.length > 0) {
        setPurchaseCount(purchases.length);
      }
    });
    return () => reaction1();
  }, [userStore.purchases]);

  const renderItem = ({ item, index, section }) => (
    <TouchableOpacity onPress={() => {
      props.navigation.navigate("service-detail", { id: item.id });
    }} style={{ padding: 10 }}>
      <Image source={item.style?.image} style={styles.itemImage} />
      <Text style={styles.itemName}>{item.name}</Text>
    </TouchableOpacity>
  );

  const category = ({ item, index, section }) => (
    <FlatList
      data={item}
      bounces={false}
      horizontal={true}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      automaticallyAdjustContentInsets={false}
      keyExtractor={(item, index) => item + index}
      renderItem={({ item, index, section }) => renderItem({ item, index, section })}
    />
  );

  return (
    <>
      <Header navigation={props.navigation} route={props.route} count={purchaseCount} />
      <View style={styles.container}>

        {!loaded ?
          <View style={styles.loading}>
            <ActivityIndicator size="large" color="#000" />
          </View>
          :
          null
        }
        <SectionList
          sections={data}
          refreshing={false}
          onRefresh={() => { }}
          style={{ width: '100%' }}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          automaticallyAdjustContentInsets={false}

          keyExtractor={(item, index) => item + index}
          renderItem={({ item, index, section }) => category({ item, index, section })}
          renderSectionHeader={({ section: { title } }) => (
            <Text style={styles.header}>{title}</Text>
          )}
        />
      </View>
    </>
  );
};

export default Home;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "#f8f9fc"
  },
  header: {
    padding: 16,
    fontSize: 16,
    color: '#333',
    backgroundColor: '#f8f9fc',
    fontFamily: 'SFProDisplay-Bold'
  },
  loading: {
    flex: 1,
    zIndex: 2,
    width: "100%",
    height: "100%",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff60"
  },
  itemName: {
    fontSize: 12,
    marginTop: 10,
    color: "#444444",
    marginBottom: 10,
    textAlign: "center",
    fontFamily: "SFProDisplay-Medium"
  },
  itemImage: {
    width: 85,
    height: 85,
    borderWidth: 2,
    borderRadius: 45,
    borderColor: "#D8D8D8"
  }
});