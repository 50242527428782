import * as React from 'react';
import { reaction } from 'mobx';
import { useStore } from '../store';
import { t } from '../services/i18n';
import { db } from '../config/firebase';
import NumberItem from '../components/NumberItem';
import { FontAwesome5 } from '@expo/vector-icons';
import Header from '../components/Header/Settings';
import { Service, Countries } from '../assets/data/enums';
import { collection, onSnapshot, doc } from 'firebase/firestore';
import { Text, View, StyleSheet, TouchableOpacity, Image, FlatList } from 'react-native';

interface NumbersProps {
  navigation?: any;
  route?: any;
}

const Numbers = (props: NumbersProps) => {
  const params = props.route.params;
  const { deviceStore, userStore } = useStore();
  const [data, setData] = React.useState<any>(userStore.purchases || []);

  React.useEffect(() => {
    const reaction1 = reaction(() => userStore.purchases, (purchases, prev) => {
      if (JSON.stringify(data) === JSON.stringify(purchases)) return;
      setData(purchases);
    });
    return () => reaction1();
  }, [userStore.purchases]);

  React.useEffect(() => {
    if (params) {
      console.log(JSON.stringify(params));
    }

    // const listenPurchases = onSnapshot(doc(collection(db, `users`), deviceStore.deviceId), (snapshot) => {
    //   if (snapshot.data().purchases) {
    //     const purchases = snapshot.data().purchases.map((item: any) => {
    //       return {
    //         ...item,
    //         service: Service[item.productId.split('_')[1]],
    //         country: Countries[item.productId.split('_')[2]]
    //       }
    //     });
    //     userStore.setPurchases(purchases);
    //   }
    // });

    // return () => {
    //   listenPurchases();
    // }
  }, []);

  return (
    <>
      <Header returnPage='home' navigation={props.navigation} title={t('numbers:header:title')} description={t('numbers:header:subtitle')} />
      <View style={styles.container}>
        {data.length == 0 ?
          <View style={styles.emptyView}>
            <View style={{ flex: 1, width: "100%", justifyContent: "center" }}>
              <Image source={require('../assets/images/empty-numbers.webp')} style={styles.emptyImage} />
              <Text style={styles.emptyTitle}>{t('numbers:empty:title')}</Text>
              <Text style={styles.emptyDescription}>{t('numbers:empty:description')}</Text>
            </View>
            <View style={{ paddingBottom: 20, width: "100%", padding: 16 }}>
              <TouchableOpacity style={styles.emptyButton} onPress={() => { props.navigation.navigate('home'); }}>
                <FontAwesome5 name="plus" size={16} color="#fff" />
                <Text style={styles.emptyButtonText}>{t('numbers:empty:buy-number')}</Text>
              </TouchableOpacity>
            </View>
          </View>
          :
          <FlatList
            data={data}
            refreshing={false}
            onRefresh={() => { }}
            style={{ width: '100%' }}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ paddingBottom: 16 }}
            ItemSeparatorComponent={() => <View style={styles.itemSeparator} />}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => <NumberItem item={item} index={index} navigation={props.navigation} route={props.route} />}
          />
        }
      </View>
    </>
  );
};

export default Numbers;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // padding: 16,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f8f9fc',
  },
  emptyView: {
    flex: 1,
    width: "100%",
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "#ffffff"
  },
  emptyImage: {
    width: "100%",
    height: 220,
    resizeMode: 'contain'
  },
  emptyTitle: {
    fontSize: 16,
    color: '#666',
    textAlign: 'center',
    fontFamily: 'SFProDisplay-Bold',
  },
  emptyDescription: {
    fontSize: 13,
    marginTop: 8,
    color: '#999999',
    textAlign: 'center',
    fontFamily: 'SFProDisplay-Regular'
  },
  emptyButton: {
    padding: 16,
    width: "100%",
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#5856d8'
  },
  emptyButtonText: {
    fontSize: 16,
    color: '#fff',
    marginLeft: 8,
    fontFamily: 'SFProDisplay-Bold'
  },
  itemSeparator: {
    height: .5,
    backgroundColor: "#e2e2e2"
  }
});
