import { t } from '../../services/i18n';
import RenderHtml from 'react-native-render-html';
import Header from '../../components/Header/Settings';
import React, { useState, useEffect, useRef } from 'react';
import { staticContent } from '../../assets/data/StaticContent';
import { Text, View, StyleSheet, Dimensions, ScrollView } from 'react-native';
import SegmentedControl from '@react-native-segmented-control/segmented-control';

interface PolicyProps {
    navigation?: any;
    route?: any;
}

const Policy = (props: PolicyProps) => {
    const scrollViewRef = useRef<any>();
    const [type, setType] = useState({
        selectedIndex: props.route.params?.type == 'terms-and-conditions' ? 1 : 0,
        type: props.route.params?.type || 'privacy',
        content: props.route.params?.type == 'privacy' ? staticContent.privactPolicy : staticContent.termsOfUse
    });

    return (
        <>
            <Header returnPage='settings' route={props.route} navigation={props.navigation} title={t('policy:header:title')} description={t('policy:header:subtitle')} />
            <View style={styles.container}>
                <SegmentedControl
                    tintColor="#5856d7"
                    fontStyle={{ color: "#5856d7", }}
                    selectedIndex={type.selectedIndex}
                    style={styles.segmentedControl}
                    activeFontStyle={{ color: "#ffffff" }}
                    values={[t('settings:sections:general:privacy_policy'), t('settings:sections:general:terms_and_conditions')]}
                    onChange={(event) => {
                        setType({
                            selectedIndex: event.nativeEvent.selectedSegmentIndex,
                            type: event.nativeEvent.selectedSegmentIndex == 0 ? 'privacy' : 'terms-and-conditions',
                            content: event.nativeEvent.selectedSegmentIndex == 0 ? staticContent.privactPolicy : staticContent.termsOfUse
                        });
                        props.navigation.setParams({
                            type: event.nativeEvent.selectedSegmentIndex == 0 ? 'privacy' : 'terms-and-conditions'
                        });
                        scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
                    }}
                />
                <ScrollView ref={scrollViewRef} style={styles.contentArea} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
                    <Text style={styles.title}>{type.selectedIndex == 0 ? t('settings:sections:general:privacy_policy') : t('settings:sections:general:terms_and_conditions')}</Text>
                    <Text style={styles.updatedDate}>Last updated on 01/01/2023</Text>
                    <RenderHtml
                        source={{ html: type.content }}
                        tagsStyles={{ p: { color: "#666" } }}
                        contentWidth={Dimensions.get('window').width}
                    />
                </ScrollView>
            </View>
        </>
    );
};

export default Policy;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: "100%",
        alignItems: 'center',
        paddingHorizontal: 16,
        justifyContent: 'center',
        backgroundColor: '#f8f9fc',
    },
    contentArea: {
        flex: 1,
        width: "100%",
        paddingHorizontal: 16,
        backgroundColor: '#f8f9fc',
    },
    title: {
        fontSize: 20,
        color: "#5856d7",
        fontWeight: "bold"
    },
    updatedDate: {
        fontSize: 12,
        color: "#666",
        marginBottom: 16
    },
    segmentedControl: {
        height: 36,
        width: '100%',
        marginTop: 16,
        marginBottom: 16
    }
});