import { t } from '../../services/i18n';
import { StatusBar } from 'expo-status-bar';
import { Entypo } from '@expo/vector-icons';
import React, { useState, useEffect } from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { View, StyleSheet, Image, TouchableOpacity, Text, Platform } from 'react-native';

interface HeaderProps {
    navigation?: any;
    route?: any;
    data?: any;
}

const Header = (props: HeaderProps) => {
    const [data, setData] = useState<any>(props.data);
    const [logoTintColor, setLogoTintColor] = useState<string>('#00000099');

    useEffect(() => {
        switch (data.id) {
            case 'tt': setLogoTintColor('#ffffff'); break;
            case 'gr': setLogoTintColor('#ffffff'); break;
            case 'ub': setLogoTintColor('#ffffff'); break;
            case 'am': setLogoTintColor('#ffffff99'); break;
            case 'ne': setLogoTintColor('#ffffff99'); break;
            default: setLogoTintColor('#00000099'); break;
        }
    }, []);

    const goBack = () => {
        props.navigation.navigate('home');
    }

    return (
        <>
            <StatusBar style="light" animated />
            <LinearGradient colors={data.style.background} style={styles.linearGradient}>
                <Image source={require('../../assets/images/logo-large.webp')} style={[styles.logo, { tintColor: logoTintColor }]} />
                <View style={styles.container}>
                    <View style={styles.buttons}>
                        <TouchableOpacity style={styles.button} onPress={goBack}>
                            <Entypo name="chevron-left" size={22} color={data.id == 'sc' ? '#000' : '#fff'} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.bottom}>
                        <Image source={data.style.imageLarge} style={styles.image} />
                        <Text style={[styles.txtInfo, { marginTop: 0, color: data.id == 'sc' ? '#000' : '#fff' }]}>✯  {t("service-detail:info:1").replace("{0}", data.countries.length)}</Text>
                        <Text style={[styles.txtInfo, { color: data.id == 'sc' ? '#000' : '#fff' }]}>✯  {t("service-detail:info:2")}</Text>
                        <Text style={[styles.txtInfo, { color: data.id == 'sc' ? '#000' : '#fff' }]}>✯  {t("service-detail:info:3")}</Text>
                    </View>
                </View>
            </LinearGradient>
        </>
    );
};

export default Header;

const styles = StyleSheet.create({
    linearGradient: {
        paddingBottom: 16,
        maxHeight: 200,
        paddingTop: Platform.OS == 'web' ? 96 : 0
    },
    container: {
        flex: 0,
        paddingHorizontal: 16,
        justifyContent: 'center'
    },
    logo: {
        top: Platform.OS == 'web' ? 16 : 0,
        right: 16,
        width: 144,
        height: 150,
        position: 'absolute',
        resizeMode: 'contain',
    },
    buttons: {
        flexDirection: 'row',
    },
    button: {
        width: 40,
        height: 40,
        marginRight: 16,
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ffffff50'
    },
    image: {
        height: 60,
        marginTop: 5,
        width: 131.25,
        resizeMode: 'contain'
    },
    txtInfo: {
        marginTop: 5,
        color: '#ffffff',
        fontFamily: 'SFProDisplay-Regular',
    }
});
