import { t } from '../../services/i18n';
import { Feather } from '@expo/vector-icons';
import Header from '../../components/Header/Settings';
import React, { useState, useEffect, useRef } from 'react';
import { Text, View, StyleSheet, FlatList, TouchableOpacity } from 'react-native';

interface FaqProps { }

const Faq = (props: FaqProps) => {
    const [data, setData] = useState<any>([
        {
            id: 1,
            title: t('faq:questions:1:question'),
            description: t('faq:questions:1:answer'),
        },
        {
            id: 2,
            title: t('faq:questions:2:question'),
            description: t('faq:questions:2:answer'),
        },
        {
            id: 3,
            title: t('faq:questions:3:question'),
            description: t('faq:questions:3:answer'),
        },
        {
            id: 4,
            title: t('faq:questions:4:question'),
            description: t('faq:questions:4:answer'),
        },
        {
            id: 5,
            title: t('faq:questions:5:question'),
            description: t('faq:questions:5:answer'),
        },
        {
            id: 6,
            title: t('faq:questions:6:question'),
            description: t('faq:questions:6:answer'),
        },
        {
            id: 7,
            title: t('faq:questions:7:question'),
            description: t('faq:questions:7:answer'),
        },
        {
            id: 8,
            title: t('faq:questions:8:question'),
            description: t('faq:questions:8:answer'),
        },
        {
            id: 9,
            title: t('faq:questions:9:question'),
            description: t('faq:questions:9:answer'),
        }
    ]);
    const [active, setActive] = useState<any>(1);

    const renderItems = ({ item, index }: any) => {
        return (
            <View style={styles.itemAccordion}>
                <TouchableOpacity style={styles.itemHeader} onPress={() => setActive(active === item.id ? null : item.id)}>
                    <Text style={styles.itemTitle}>{item.title}</Text>
                    <Feather name={active === item.id ? "chevron-up" : "chevron-down"} size={24} color="#000" />
                </TouchableOpacity>
                {active === item.id && (
                    <View style={styles.itemBody}>
                        <Text style={styles.itemDescription}>{item.description}</Text>
                    </View>
                )}
            </View>
        )
    }

    return (
        <>
            <Header returnPage='settings' route={props.route} navigation={props.navigation} title={t('faq:header:title')} description={t('faq:header:subtitle')} />
            <View style={styles.container}>
                <FlatList
                    data={data}
                    style={{ width: "100%" }}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                    keyExtractor={(item, index) => item + index}
                    renderItem={({ item, index }) => renderItems({ item, index })}
                />
            </View>
        </>
    );
};

export default Faq;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        width: "100%",
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f8f9fc',
    },
    itemAccordion: {
        marginBottom: 10,
        borderRadius: 10,
        overflow: "hidden",
        backgroundColor: "#fff"
    },
    itemHeader: {
        padding: 16,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    itemTitle: {
        color: "#333444",
        fontFamily: "SFProDisplay-Medium"
    },
    itemBody: {
        paddingBottom: 16,
        paddingHorizontal: 16,
    },
    itemDescription: {
        fontSize: 13,
        color: "#666",
        fontFamily: "SFProDisplay-Regular"
    },
});
