import * as React from 'react';
import { useStore } from '../store';
import CountryItem from '../components/CountryItem';
import Header from '../components/Header/ServiceDetail';
import { Text, View, StyleSheet, SafeAreaView, FlatList } from 'react-native';

interface ServiceDetailProps {
    navigation?: any;
    route?: any;
}

const ServiceDetail = (props: ServiceDetailProps) => {
    const { serviceStore } = useStore();
    const id = props.route.params.id;

    const [data, setData] = React.useState<any>(serviceStore.services.reduce(function (acc, item) {
        var filteredData = item.data[0].filter(function (subItem) {
            return subItem.id === props.route.params.id;
        });
        if (filteredData.length > 0) {
            acc = filteredData[0];
        }
        return acc;
    }, {}));

    React.useEffect(() => {
        if (data.countries.length == 0) {
            props.navigation.reset({
                index: 0,
                routes: [{ name: "home" }],
            });
        }

        props.navigation.setOptions({
            title: data.name,
        });
    }, []);

    return (
        <SafeAreaView style={{ backgroundColor: data.style.background[0], flex: 1 }}>
            <Header navigation={props.navigation} route={props.route} data={data} />
            <View style={styles.container}>
                <FlatList
                    data={data.countries}
                    style={{ width: "100%" }}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                    keyExtractor={(item, index) => item + index}
                    ListFooterComponent={() => (<View style={{ height: 1 }} />)}
                    ListHeaderComponent={() => (<View style={{ height: 16 }} />)}
                    renderItem={({ index, item }) => (<CountryItem navigation={props.navigation} service={data} id={item.id} item={item} index={index} color={data.style.background} serviceId={props.route.params.id} />)}
                />
            </View>
        </SafeAreaView>
    );
};

export default ServiceDetail;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: "100%",
        paddingHorizontal: 16,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f8f9fc',
    },
});