import React from 'react';
import { Entypo } from '@expo/vector-icons';
import { sessionStorage } from '../../services/Storage';
import { SafeAreaView } from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { View, StyleSheet, Image, TouchableOpacity, Platform, Text } from 'react-native';


interface HeaderProps {
    navigation?: any;
    route?: any;
    title?: string;
    subtitle?: string;
    description?: string;
    returnPage?: string;
}

const Header = (props: HeaderProps) => {

    const goBack = async () => {
        if (props.navigation.canGoBack()) {
            props.navigation.goBack();
            return;
        }
        if (props.returnPage) {
            props.navigation.reset({
                index: 0,
                routes: [{ name: props.returnPage }],
            });
            return;
        }
        props.navigation.navigate('home');
    }

    return (
        <>
            <SafeAreaView edges={["top"]} style={{ flex: 0, backgroundColor: "#fff" }} />
            <View style={styles.container}>
                <View style={styles.header}>
                    <View style={styles.buttons}>
                        <TouchableOpacity style={styles.button} onPress={goBack}>
                            <Entypo name="chevron-left" size={22} color="#5856d7" />
                        </TouchableOpacity>
                    </View>
                    <View style={{ flex: 1 }}>
                        <Text style={styles.txtTitle}>{props.title} <Text style={{ fontSize: 12, color: "#444" }}>{props.subtitle}</Text></Text>
                        <Text style={styles.txtSubtitle}>{props.description}</Text>
                    </View>
                </View>
            </View>
        </>
    );
};

export default Header;

const styles = StyleSheet.create({
    // container: {
    //     flex: 0,
    //     padding: 16,
    //     paddingTop: 10,
    //     backgroundColor: '#5856d7',
    //     minHeight: Platform.OS === 'web' ? 70 : 50,
    // },
    container: {
        flex: 0,
        padding: 16,
        paddingTop: Platform.OS === 'web' ? 10 : 0,
        backgroundColor: '#fff',
        borderBottomColor: '#e6e6e6',
        borderBottomWidth: .5,
        minHeight: Platform.OS === 'web' ? 70 : 50,
    },
    header: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    buttons: {
        flexDirection: 'row',
    },
    // button: {
    //     width: 40,
    //     height: 40,
    //     borderWidth: 3,
    //     marginRight: 15,
    //     borderRadius: 16,
    //     alignItems: 'center',
    //     borderColor: '#504fc6',
    //     justifyContent: 'center',
    //     backgroundColor: '#5251ca'
    // },
    button: {
        width: 40,
        height: 40,
        // borderWidth: 2,
        marginRight: 16,
        borderRadius: 15,
        alignItems: 'center',
        // borderColor: '#eeeeee50',
        justifyContent: 'center',
        backgroundColor: '#f8f9fc'
    },
    txtTitle: {
        fontSize: 30,
        color: '#5856d7',
        textAlign: 'left',
        fontFamily: 'SFProDisplay-Bold',
    },
    txtSubtitle: {
        fontSize: 11,
        color: '#b0b0b0',
        textAlign: 'left',
        fontFamily: 'SFProDisplay-Regular',
    },
    logo: {
        width: 100,
        height: 33,
        tintColor: '#5856d7',
        resizeMode: 'contain'
    },
});
