import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB77gv7KCzNyV6CZ_TtSGtBQy2_JPzm-tw",
    authDomain: "i-verify.firebaseapp.com",
    projectId: "i-verify",
    storageBucket: "i-verify.appspot.com",
    messagingSenderId: "8165495126",
    appId: "1:8165495126:web:c5e2e62a48557d44cac6f7",
    measurementId: "G-6SVJJVLG8L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };






// import { db, analytics } from '../config/firebase';
// import { collection, onSnapshot } from 'firebase/firestore';
// import { logEvent, setUserId } from '../services/firebase/analytics';

// React.useEffect(() => {
//     const collectionRef = collection(db, 'Services');

//     const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
//         for (const doc of snapshot.docs) {
//             console.log(doc.id, '=>', doc.data());
//         }
//     });

//     return () => unsubscribe();
// }, []);


// import { t } from '../services/i18n';
// t("home:test")





// const servicesRef = await collection(db, 'Services');
//       const data = await getDocs(servicesRef).then((querySnapshot) => {
//         const entities: any = [];
//         querySnapshot.forEach((doc: any) => {
//           entities.push({ id: doc.id, ...doc.data() });
//         });
//         return entities;
//       });

//       return data.map((doc) => {
//         return {
//           id: doc.id,
//           name: Service[doc.id].name,
//           description: Service[doc.id].description,
//           recommended: doc.r,
//           category: Service[doc.id].category,
//           o: doc.o,
//           style: {
//             color: Service[doc.id].style.color,
//             fontName: Service[doc.id].style.font,
//             iconName: Service[doc.id].style.icon,
//             image: Service[doc.id].style.image,
//             imageLarge: Service[doc.id].style.imageLarge,
//             background: Service[doc.id].style.background,
//           },
//           countries: doc.c.map((item) => {
//             if (HighPriceServices.indexOf(`sbs_${doc.id}_${item.cc}`) == -1) {
//               return {
//                 code: item.cc,
//                 availableCount: item.ac,
//                 name: t(`common:countries:${item.cc}`),
//                 productId: `sbs_${doc.id}_${item.cc}`,
//                 areaCode: Countries[item.cc].areaCode,
//                 image: Countries[item.cc].image,
//               }
//             }
//           }).filter(a => a != null).sort((a, b) => a.name > b.name)
//         }
//       });