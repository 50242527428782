import { t } from '../services/i18n';
import { Feather } from '@expo/vector-icons';
import { Text, View, StyleSheet, TouchableOpacity, Image } from 'react-native';

interface CountryItemProps {
    navigation?: any;
    id?: string;
    item?: any;
    index?: number;
    color?: [string, string];
    serviceId?: string;
    service: any;
}

const CountryItem = (props: CountryItemProps) => {
    return (
        <TouchableOpacity onPress={() => {
            props.navigation.navigate('purchase', {
                service: props.service,
                country: props.item,
            });
        }} style={styles.container}>
            <Image source={props.item.image} style={styles.image} />
            <Text style={styles.areaCode}>+ {props.item.areaCode}</Text>
            <Text style={styles.name}>{props.item.name}</Text>
            <View style={[styles.priceArea, { backgroundColor: props.color[0] }]}>
                <Text style={[styles.price, { color: props.serviceId == 'sc' ? '#333' : '#fff' }]}>{t('common:buy')}</Text>
            </View>
        </TouchableOpacity>
    );
};

export default CountryItem;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        marginBottom: 8,
        borderRadius: 16,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "#ffffff",
        borderWidth: .2,
        borderColor: "#d8d8d8",
    },
    image: {
        width: 36,
        height: 36,
        borderRadius: 18,
        borderWidth: 1.5,
        borderColor: "#D8D8D8"
    },
    areaCode: {
        marginLeft: 16,
        width: 42,
        fontFamily: "SFProDisplay-Regular",
    },
    name: {
        marginLeft: 16,
        fontFamily: "SFProDisplay-Medium",
        flex: 1,
    },
    priceArea: {
        borderRadius: 16,
        paddingVertical: 8,
        paddingHorizontal: 16,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#45AF5A"
    },
    price: {
        color: "#ffffff",
        fontFamily: "SFProDisplay-Bold",
    }
});