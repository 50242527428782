import { t } from '../../services/i18n';
import { StatusBar } from 'expo-status-bar';
import { Entypo } from '@expo/vector-icons';
import React, { useState, useEffect } from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { View, StyleSheet, Image, TouchableOpacity, Text, Platform } from 'react-native';

interface HeaderProps {
    navigation?: any;
    route?: any;
    service?: any;
    country?: any;
}

const Header = (props: HeaderProps) => {
    const [logoTintColor, setLogoTintColor] = useState<string>('#00000099');

    useEffect(() => {
        switch (props.service.id) {
            case 'tt': setLogoTintColor('#ffffff'); break;
            case 'gr': setLogoTintColor('#ffffff'); break;
            case 'ub': setLogoTintColor('#ffffff'); break;
            case 'am': setLogoTintColor('#ffffff99'); break;
            case 'ne': setLogoTintColor('#ffffff99'); break;
            default: setLogoTintColor('#00000099'); break;
        }
    }, []);

    const goBack = () => {
        props.navigation.goBack();
    }

    return (
        <>
            <StatusBar style="light" animated />
            <LinearGradient colors={props.service.style.background} style={styles.linearGradient}>
                <Image source={require('../../assets/images/logo-large.webp')} style={[styles.logo, { tintColor: logoTintColor }]} />
                <View style={styles.container}>
                    <View style={styles.buttons}>
                        <TouchableOpacity style={styles.button} onPress={goBack}>
                            <Entypo name="chevron-left" size={22} color={props.service.id == 'sc' ? '#000' : '#fff'} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.bottom}>
                        <Image source={props.service.style.imageLarge} style={styles.image} />
                        <View style={{ height: 61 }}>

                        </View>
                    </View>
                </View>
            </LinearGradient>
            <View style={styles.countryInfo}>
                <Image source={props.country.image} style={styles.countryImage} />
                <View style={styles.info}>
                    <Text style={[styles.countryName, { color: props.service.id == 'sc' ? '#333' : '#fff' }]}>{props.country.name}</Text>
                    <Text style={[styles.areaCode, { color: props.service.id == 'sc' ? '#333' : '#fff' }]}>+{props.country.areaCode} XXX-XXX-XXXX</Text>
                </View>
            </View>
        </>
    );
};

export default Header;

const styles = StyleSheet.create({
    linearGradient: {
        maxHeight: 200,
        paddingBottom: 16,
        paddingTop: Platform.OS == 'web' ? 96 : 0
    },
    container: {
        flex: 0,
        paddingHorizontal: 16,
        justifyContent: 'center'
    },
    logo: {
        top: Platform.OS == 'web' ? 16 : 0,
        right: 16,
        width: 144,
        height: 150,
        position: 'absolute',
        resizeMode: 'contain',
    },
    buttons: {
        flexDirection: 'row',
    },
    button: {
        width: 40,
        height: 40,
        marginRight: 16,
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ffffff50'
    },
    image: {
        height: 60,
        marginTop: 5,
        width: 131.25,
        resizeMode: 'contain'
    },
    txtInfo: {
        marginTop: 5,
        color: '#ffffff',
        fontFamily: 'SFProDisplay-Regular',
    },
    countryInfo: {
        left: 16,
        top: Platform.OS == 'web' ? 125 : 155,
        zIndex: 1,
        position: 'absolute',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    countryImage: {
        width: 110,
        height: 110,
        borderWidth: 7.5,
        borderRadius: 55,
        borderColor: "#fff",
        resizeMode: 'contain',
    },
    info: {
        marginLeft: 16,
        marginTop: -55,
    },
    countryName: {
        fontSize: 24,
        color: '#ffffff',
        fontFamily: 'SFProDisplay-Bold'
    },
    areaCode: {
        fontSize: 15,
        marginTop: 6,
        color: '#ffffff',
        fontFamily: 'SFProDisplay-Regular'
    },
});
