export const Service = {
    wa: {
        name: 'WhatsApp',
        description: 'WhatsApp & WhatsApp Business',
        category: '1',
        style: {
            color: '#4FCE5D',
            font: 'Ionicons',
            icon: 'logo-whatsapp',
            image: require('../images/services/whatsapp.webp'),
            imageLarge: require('../images/services/large/whatsapp.webp'),
            background: ["#03D15D", "#03B250"]
        }
    },
    in: {
        name: 'Instagram',
        description: 'Instagram',
        category: '2',
        style: {
            color: '#FB3958',
            font: 'Ionicons',
            icon: 'logo-instagram',
            image: require('../images/services/instagram.webp'),
            imageLarge: require('../images/services/large/instagram.webp'),
            background: ["#FF0051", "#8C3BD0"]
        }
    },
    yo: {
        name: 'Youtube',
        description: 'Youtube',
        category: '3',
        style: {
            color: '#FF0000',
            font: 'Ionicons',
            icon: 'logo-youtube',
            image: require('../images/services/youtube.webp'),
            imageLarge: require('../images/services/large/youtube.webp'),
            background: ["#FF0000", "#B22D2D"]
        }
    },
    fa: {
        name: 'Facebook',
        description: 'Facebook & Facebook Messenger',
        category: '2',
        style: {
            color: '#4267B2',
            font: 'Ionicons',
            icon: 'logo-facebook',
            image: require('../images/services/facebook.webp'),
            imageLarge: require('../images/services/large/facebook.webp'),
            background: ["#337FFF", "#2C6CD8"]
        }
    },
    tw: {
        name: 'Twitter',
        description: 'Twitter',
        category: '2',
        style: {
            color: '#1DA1F2',
            font: 'Ionicons',
            icon: 'logo-twitter',
            image: require('../images/services/twitter.webp'),
            imageLarge: require('../images/services/large/twitter.webp'),
            background: ["#33CCFF", "#2C6CD8"]
        }
    },
    ti: {
        name: 'Tinder',
        description: 'Tinder',
        category: '4',
        style: {
            color: '#FE3C72',
            font: 'Fontisto',
            icon: 'tinder',
            image: require('../images/services/tinder.webp'),
            imageLarge: require('../images/services/large/tinder.webp'),
            background: ["#EF8C52", "#CE5184"]
        }
    },
    ne: {
        name: 'Netflix',
        description: 'Netflix',
        category: '3',
        style: {
            color: '#E50914',
            font: 'Fontisto',
            icon: 'netflix',
            image: require('../images/services/netflix.webp'),
            imageLarge: require('../images/services/large/netflix.webp'),
            background: ["#000000", "#111"]
        }
    },
    te: {
        name: 'Telegram',
        description: 'Telegram',
        category: '1',
        style: {
            color: '#0088CC',
            font: 'Fontisto',
            icon: 'telegram',
            image: require('../images/services/telegram.webp'),
            imageLarge: require('../images/services/large/telegram.webp'),
            background: ["#34AADF", "#2C6CD8"]
        }
    },
    am: {
        name: 'Amazon',
        description: 'Amazon',
        category: '3',
        style: {
            color: '#000000',
            font: 'Fontisto',
            icon: 'amazon',
            image: require('../images/services/amazon.webp'),
            imageLarge: require('../images/services/large/amazon.webp'),
            background: ["#000000", "#3D3D3D"]
        }
    },
    gr: {
        name: 'Grindr',
        description: 'Grindr',
        category: '4',
        style: {
            color: '#F5B849',
            font: 'Entypo',
            icon: 'mask',
            image: require('../images/services/grindr.webp'),
            imageLarge: require('../images/services/large/grindr.webp'),
            background: ["#000000", "#000000"]
        }
    },
    li: {
        name: 'Line',
        description: 'Line',
        category: '1',
        style: {
            color: '#1EC32E',
            font: 'Fontisto',
            icon: 'line',
            image: require('../images/services/line.webp'),
            imageLarge: require('../images/services/large/line.webp'),
            background: ["#3ACE01", "#31AD01"]
        }
    },
    pa: {
        name: 'PayPal',
        description: 'PayPal',
        category: '5',
        style: {
            color: '#002F83',
            font: 'Fontisto',
            icon: 'paypal-p',
            image: require('../images/services/paypal.webp'),
            imageLarge: require('../images/services/large/paypal.webp'),
            background: ["#009BD9", "#2C6CD8"]
        }
    },
    si: {
        name: 'Signal',
        description: 'Signal',
        category: '1',
        style: {
            color: '#3872E9',
            font: 'Ionicons',
            icon: 'ios-chatbubble',
            image: require('../images/services/signal.webp'),
            imageLarge: require('../images/services/large/signal.webp'),
            background: ["#3A76F0", "#3A76F0"]
        }
    },
    sc: {
        name: 'Snapchat',
        description: 'Snapchat',
        category: '2',
        style: {
            color: '#F7F400',
            font: 'Ionicons',
            icon: 'logo-snapchat',
            image: require('../images/services/snapchat.webp'),
            imageLarge: require('../images/services/large/snapchat.webp'),
            background: ["#FFF853", "#FFF853"]
        }
    },
    ub: {
        name: 'Uber',
        description: 'Uber',
        category: '5',
        style: {
            color: '#000000',
            font: 'Fontisto',
            icon: 'uber',
            image: require('../images/services/uber.webp'),
            imageLarge: require('../images/services/large/uber.webp'),
            background: ["#000000", "#000000"]
        }
    },
    vi: {
        name: 'Viber',
        description: 'Viber',
        category: '1',
        style: {
            color: '#6E4ED2',
            font: 'Fontisto',
            icon: 'viber',
            image: require('../images/services/viber.webp'),
            imageLarge: require('../images/services/large/viber.webp'),
            background: ["#754A91", "#5E3777"]
        }
    },
    ya: {
        name: 'Yahoo',
        description: 'Yahoo',
        category: '5',
        style: {
            color: '#5E21CF',
            font: 'Ionicons',
            icon: 'logo-yahoo',
            image: require('../images/services/yahoo.webp'),
            imageLarge: require('../images/services/large/yahoo.webp'),
            background: ["#6001D1", "#6001D1"]
        }
    },
    ly: {
        name: 'Lyft',
        description: 'Lyft',
        category: '5',
        style: {
            color: '#5E21CF',
            font: 'Ionicons',
            icon: 'logo-yahoo',
            image: require('../images/services/lyft.webp'),
            imageLarge: require('../images/services/large/lyft.webp'),
            background: ["#EA0B8C", "#C71680"]
        }
    },
    ta: {
        name: 'Tango',
        description: 'Tango',
        category: '1',
        style: {
            color: '#5E21CF',
            font: 'Ionicons',
            icon: 'logo-yahoo',
            image: require('../images/services/tango.webp'),
            imageLarge: require('../images/services/large/tango.webp'),
            background: ["#FFAD00", "#FF2581"]
        }
    },
    tt: {
        name: 'Tiktok',
        description: 'Tiktok',
        category: '2',
        style: {
            color: '#5E21CF',
            font: 'Ionicons',
            icon: 'logo-yahoo',
            image: require('../images/services/tiktok.webp'),
            imageLarge: require('../images/services/large/tiktok.webp'),
            background: ["#000000", "#000000"]
        }
    },
    ve: {
        name: 'WeChat',
        description: 'WeChat',
        category: '1',
        style: {
            color: '#5E21CF',
            font: 'Ionicons',
            icon: 'logo-yahoo',
            image: require('../images/services/wechat.webp'),
            imageLarge: require('../images/services/large/wechat.webp'),
            background: ["#51C332", "#43A429"]
        }
    }
};

export const Countries = {
    ar: {
        name: 'Argentina',
        areaCode: 54,
        flag: '🇦🇷',
        image: require('../images/countries/ar.webp'),
    },
    br: {
        name: 'Brazil',
        areaCode: 55,
        flag: '🇧🇷',
        image: require('../images/countries/br.webp'),
    },
    de: {
        name: 'Germany',
        areaCode: 49,
        flag: '🇩🇪',
        image: require('../images/countries/de.webp'),
    },
    es: {
        name: 'Spain',
        areaCode: 34,
        flag: '🇪🇸',
        image: require('../images/countries/es.webp'),
    },
    fr: {
        name: 'France',
        areaCode: 33,
        flag: '🇫🇷',
        image: require('../images/countries/fr.webp'),
    },
    id: {
        name: 'Indonesia',
        areaCode: 62,
        flag: '🇮🇩',
        image: require('../images/countries/id.webp'),
    },
    ie: {
        name: 'Ireland',
        areaCode: 353,
        flag: '🇮🇪',
        image: require('../images/countries/ie.webp'),
    },
    ke: {
        name: 'Kenya',
        areaCode: 254,
        flag: '🇰🇪',
        image: require('../images/countries/ke.webp'),
    },
    kg: {
        name: 'Kyrgyzstan',
        areaCode: 996,
        flag: '🇰🇬',
        image: require('../images/countries/kg.webp'),
    },
    kh: {
        name: 'Cambodia',
        areaCode: 855,
        flag: '🇰🇭',
        image: require('../images/countries/kh.webp'),
    },
    la: {
        name: 'Laos',
        areaCode: 856,
        flag: '🇱🇦',
        image: require('../images/countries/la.webp'),
    },
    lt: {
        name: 'Lithuania',
        areaCode: 370,
        flag: '🇱🇹',
        image: require('../images/countries/lt.webp'),
    },
    lv: {
        name: 'Latvia',
        areaCode: 371,
        flag: '🇱🇻',
        image: require('../images/countries/lv.webp'),
    },
    nl: {
        name: 'Netherlands',
        areaCode: 31,
        flag: '🇳🇱',
        image: require('../images/countries/nl.webp'),
    },
    pl: {
        name: 'Poland',
        areaCode: 48,
        flag: '🇵🇱',
        image: require('../images/countries/pl.webp'),
    },
    ro: {
        name: 'Romania',
        areaCode: 40,
        flag: '🇷🇴',
        image: require('../images/countries/ro.webp'),
    },
    ru: {
        name: 'Russia',
        areaCode: 7,
        flag: '🇷🇺',
        image: require('../images/countries/ru.webp'),
    },
    se: {
        name: 'Sweden',
        areaCode: 46,
        flag: '🇸🇪',
        image: require('../images/countries/se.webp'),
    },
    ua: {
        name: 'Ukraine',
        areaCode: 380,
        flag: '🇺🇦',
        image: require('../images/countries/ua.webp'),
    },
    uk: {
        name: 'United Kingdom',
        areaCode: 44,
        flag: '🇬🇧',
        image: require('../images/countries/uk.webp'),
    },
    us: {
        name: 'United States',
        areaCode: 1,
        flag: '🇺🇸',
        image: require('../images/countries/us.webp'),
    },
    vn: {
        name: 'Vietnam',
        areaCode: 84,
        flag: '🇻🇳',
        image: require('../images/countries/vn.webp'),
    }
};

export const HighPriceServices = [
    'sbs_in_us', //Instagram - Amerika
    'sbs_te_ie', // Telegram - Irlanda
    'sbs_vi_us', // Viber - Amerika
    'sbs_wa_ie', // WhatsApp - Irlanda
    'sbs_yo_us' // Youtube - Amerika
];

enum PriceLevel {
    Low = 1,
    Medium = 2,
    High = 3
}

export const ServiceLevels = {
    sbs_wa_ar: PriceLevel.Medium,
    sbs_wa_br: PriceLevel.Medium,
    sbs_wa_de: PriceLevel.Medium,
    sbs_wa_es: PriceLevel.Medium,
    sbs_wa_fr: PriceLevel.Medium,
    sbs_wa_id: PriceLevel.Medium,
    sbs_wa_ie: PriceLevel.High,
    sbs_wa_ke: PriceLevel.Medium,
    sbs_wa_kg: PriceLevel.Medium,
    sbs_wa_kh: PriceLevel.Medium,
    sbs_wa_la: PriceLevel.Medium,
    sbs_wa_lt: PriceLevel.Medium,
    sbs_wa_lv: PriceLevel.Medium,
    sbs_wa_nl: PriceLevel.Medium,
    sbs_wa_pl: PriceLevel.Medium,
    sbs_wa_ro: PriceLevel.Medium,
    sbs_wa_ru: PriceLevel.Medium,
    sbs_wa_se: PriceLevel.Medium,
    sbs_wa_ua: PriceLevel.Medium,
    sbs_wa_uk: PriceLevel.Medium,
    sbs_wa_us: PriceLevel.Medium,
    sbs_wa_vn: PriceLevel.Medium,

    sbs_in_ar: PriceLevel.Medium,
    sbs_in_br: PriceLevel.Medium,
    sbs_in_de: PriceLevel.Medium,
    sbs_in_es: PriceLevel.Medium,
    sbs_in_fr: PriceLevel.Medium,
    sbs_in_id: PriceLevel.Medium,
    sbs_in_ie: PriceLevel.Medium,
    sbs_in_ke: PriceLevel.Medium,
    sbs_in_kg: PriceLevel.Medium,
    sbs_in_kh: PriceLevel.Medium,
    sbs_in_la: PriceLevel.Medium,
    sbs_in_lt: PriceLevel.Medium,
    sbs_in_lv: PriceLevel.Medium,
    sbs_in_nl: PriceLevel.Medium,
    sbs_in_pl: PriceLevel.Medium,
    sbs_in_ro: PriceLevel.Medium,
    sbs_in_ru: PriceLevel.Medium,
    sbs_in_se: PriceLevel.Medium,
    sbs_in_ua: PriceLevel.Medium,
    sbs_in_uk: PriceLevel.Medium,
    sbs_in_us: PriceLevel.High,
    sbs_in_vn: PriceLevel.Medium,

    sbs_yo_ar: PriceLevel.Medium,
    sbs_yo_br: PriceLevel.Medium,
    sbs_yo_de: PriceLevel.Medium,
    sbs_yo_es: PriceLevel.Medium,
    sbs_yo_fr: PriceLevel.Medium,
    sbs_yo_id: PriceLevel.Medium,
    sbs_yo_ie: PriceLevel.Medium,
    sbs_yo_ke: PriceLevel.Medium,
    sbs_yo_kg: PriceLevel.Medium,
    sbs_yo_kh: PriceLevel.Medium,
    sbs_yo_la: PriceLevel.Medium,
    sbs_yo_lt: PriceLevel.Medium,
    sbs_yo_lv: PriceLevel.Medium,
    sbs_yo_nl: PriceLevel.Medium,
    sbs_yo_pl: PriceLevel.Medium,
    sbs_yo_ro: PriceLevel.Medium,
    sbs_yo_ru: PriceLevel.Medium,
    sbs_yo_se: PriceLevel.Medium,
    sbs_yo_ua: PriceLevel.Medium,
    sbs_yo_uk: PriceLevel.Medium,
    sbs_yo_us: PriceLevel.High,
    sbs_yo_vn: PriceLevel.Medium,

    sbs_fa_ar: PriceLevel.Medium,
    sbs_fa_br: PriceLevel.Medium,
    sbs_fa_de: PriceLevel.Medium,
    sbs_fa_es: PriceLevel.Medium,
    sbs_fa_fr: PriceLevel.Medium,
    sbs_fa_id: PriceLevel.Medium,
    sbs_fa_ie: PriceLevel.Medium,
    sbs_fa_ke: PriceLevel.Medium,
    sbs_fa_kg: PriceLevel.Medium,
    sbs_fa_kh: PriceLevel.Medium,
    sbs_fa_la: PriceLevel.Medium,
    sbs_fa_lt: PriceLevel.Medium,
    sbs_fa_lv: PriceLevel.Medium,
    sbs_fa_nl: PriceLevel.Medium,
    sbs_fa_pl: PriceLevel.Medium,
    sbs_fa_ro: PriceLevel.Medium,
    sbs_fa_ru: PriceLevel.Medium,
    sbs_fa_se: PriceLevel.Medium,
    sbs_fa_ua: PriceLevel.Medium,
    sbs_fa_uk: PriceLevel.Medium,
    sbs_fa_us: PriceLevel.Medium,
    sbs_fa_vn: PriceLevel.Medium,

    sbs_tw_ar: PriceLevel.Medium,
    sbs_tw_br: PriceLevel.Medium,
    sbs_tw_de: PriceLevel.Medium,
    sbs_tw_es: PriceLevel.Medium,
    sbs_tw_fr: PriceLevel.Medium,
    sbs_tw_id: PriceLevel.Medium,
    sbs_tw_ie: PriceLevel.Medium,
    sbs_tw_ke: PriceLevel.Medium,
    sbs_tw_kg: PriceLevel.Medium,
    sbs_tw_kh: PriceLevel.Medium,
    sbs_tw_la: PriceLevel.Medium,
    sbs_tw_lt: PriceLevel.Medium,
    sbs_tw_lv: PriceLevel.Medium,
    sbs_tw_nl: PriceLevel.Medium,
    sbs_tw_pl: PriceLevel.Medium,
    sbs_tw_ro: PriceLevel.Medium,
    sbs_tw_ru: PriceLevel.Medium,
    sbs_tw_se: PriceLevel.Medium,
    sbs_tw_ua: PriceLevel.Medium,
    sbs_tw_uk: PriceLevel.Medium,
    sbs_tw_us: PriceLevel.Medium,
    sbs_tw_vn: PriceLevel.Medium,

    sbs_ti_ar: PriceLevel.Medium,
    sbs_ti_br: PriceLevel.Medium,
    sbs_ti_de: PriceLevel.Medium,
    sbs_ti_es: PriceLevel.Medium,
    sbs_ti_fr: PriceLevel.Medium,
    sbs_ti_id: PriceLevel.Medium,
    sbs_ti_ie: PriceLevel.Medium,
    sbs_ti_ke: PriceLevel.Medium,
    sbs_ti_kg: PriceLevel.Medium,
    sbs_ti_kh: PriceLevel.Medium,
    sbs_ti_la: PriceLevel.Medium,
    sbs_ti_lt: PriceLevel.Medium,
    sbs_ti_lv: PriceLevel.Medium,
    sbs_ti_nl: PriceLevel.Medium,
    sbs_ti_pl: PriceLevel.Medium,
    sbs_ti_ro: PriceLevel.Medium,
    sbs_ti_ru: PriceLevel.Medium,
    sbs_ti_se: PriceLevel.Medium,
    sbs_ti_ua: PriceLevel.Medium,
    sbs_ti_uk: PriceLevel.Medium,
    sbs_ti_us: PriceLevel.Medium,
    sbs_ti_vn: PriceLevel.Medium,

    sbs_ne_ar: PriceLevel.Medium,
    sbs_ne_br: PriceLevel.Medium,
    sbs_ne_de: PriceLevel.Medium,
    sbs_ne_es: PriceLevel.Medium,
    sbs_ne_fr: PriceLevel.Medium,
    sbs_ne_id: PriceLevel.Medium,
    sbs_ne_ie: PriceLevel.Medium,
    sbs_ne_ke: PriceLevel.Medium,
    sbs_ne_kg: PriceLevel.Medium,
    sbs_ne_kh: PriceLevel.Medium,
    sbs_ne_la: PriceLevel.Medium,
    sbs_ne_lt: PriceLevel.Medium,
    sbs_ne_lv: PriceLevel.Medium,
    sbs_ne_nl: PriceLevel.Medium,
    sbs_ne_pl: PriceLevel.Medium,
    sbs_ne_ro: PriceLevel.Medium,
    sbs_ne_ru: PriceLevel.Medium,
    sbs_ne_se: PriceLevel.Medium,
    sbs_ne_ua: PriceLevel.Medium,
    sbs_ne_uk: PriceLevel.Medium,
    sbs_ne_us: PriceLevel.Medium,
    sbs_ne_vn: PriceLevel.Medium,

    sbs_te_ar: PriceLevel.Medium,
    sbs_te_br: PriceLevel.Medium,
    sbs_te_de: PriceLevel.Medium,
    sbs_te_es: PriceLevel.Medium,
    sbs_te_fr: PriceLevel.Medium,
    sbs_te_id: PriceLevel.Medium,
    sbs_te_ie: PriceLevel.High,
    sbs_te_ke: PriceLevel.Medium,
    sbs_te_kg: PriceLevel.Medium,
    sbs_te_kh: PriceLevel.Medium,
    sbs_te_la: PriceLevel.Medium,
    sbs_te_lt: PriceLevel.Medium,
    sbs_te_lv: PriceLevel.Medium,
    sbs_te_nl: PriceLevel.Medium,
    sbs_te_pl: PriceLevel.Medium,
    sbs_te_ro: PriceLevel.Medium,
    sbs_te_ru: PriceLevel.Medium,
    sbs_te_se: PriceLevel.Medium,
    sbs_te_ua: PriceLevel.Medium,
    sbs_te_uk: PriceLevel.Medium,
    sbs_te_us: PriceLevel.Medium,
    sbs_te_vn: PriceLevel.Medium,

    sbs_am_ar: PriceLevel.Medium,
    sbs_am_br: PriceLevel.Medium,
    sbs_am_de: PriceLevel.Medium,
    sbs_am_es: PriceLevel.Medium,
    sbs_am_fr: PriceLevel.Medium,
    sbs_am_id: PriceLevel.Medium,
    sbs_am_ie: PriceLevel.Medium,
    sbs_am_ke: PriceLevel.Medium,
    sbs_am_kg: PriceLevel.Medium,
    sbs_am_kh: PriceLevel.Medium,
    sbs_am_la: PriceLevel.Medium,
    sbs_am_lt: PriceLevel.Medium,
    sbs_am_lv: PriceLevel.Medium,
    sbs_am_nl: PriceLevel.Medium,
    sbs_am_pl: PriceLevel.Medium,
    sbs_am_ro: PriceLevel.Medium,
    sbs_am_ru: PriceLevel.Medium,
    sbs_am_se: PriceLevel.Medium,
    sbs_am_ua: PriceLevel.Medium,
    sbs_am_uk: PriceLevel.Medium,
    sbs_am_us: PriceLevel.Medium,
    sbs_am_vn: PriceLevel.Medium,

    sbs_gr_ar: PriceLevel.Medium,
    sbs_gr_br: PriceLevel.Medium,
    sbs_gr_de: PriceLevel.Medium,
    sbs_gr_es: PriceLevel.Medium,
    sbs_gr_fr: PriceLevel.Medium,
    sbs_gr_id: PriceLevel.Medium,
    sbs_gr_ie: PriceLevel.Medium,
    sbs_gr_ke: PriceLevel.Medium,
    sbs_gr_kg: PriceLevel.Medium,
    sbs_gr_kh: PriceLevel.Medium,
    sbs_gr_la: PriceLevel.Medium,
    sbs_gr_lt: PriceLevel.Medium,
    sbs_gr_lv: PriceLevel.Medium,
    sbs_gr_nl: PriceLevel.Medium,
    sbs_gr_pl: PriceLevel.Medium,
    sbs_gr_ro: PriceLevel.Medium,
    sbs_gr_ru: PriceLevel.Medium,
    sbs_gr_se: PriceLevel.Medium,
    sbs_gr_ua: PriceLevel.Medium,
    sbs_gr_uk: PriceLevel.Medium,
    sbs_gr_us: PriceLevel.Medium,
    sbs_gr_vn: PriceLevel.Medium,

    sbs_li_ar: PriceLevel.Medium,
    sbs_li_br: PriceLevel.Medium,
    sbs_li_de: PriceLevel.Medium,
    sbs_li_es: PriceLevel.Medium,
    sbs_li_fr: PriceLevel.Medium,
    sbs_li_id: PriceLevel.Medium,
    sbs_li_ie: PriceLevel.Medium,
    sbs_li_ke: PriceLevel.Medium,
    sbs_li_kg: PriceLevel.Medium,
    sbs_li_kh: PriceLevel.Medium,
    sbs_li_la: PriceLevel.Medium,
    sbs_li_lt: PriceLevel.Medium,
    sbs_li_lv: PriceLevel.Medium,
    sbs_li_nl: PriceLevel.Medium,
    sbs_li_pl: PriceLevel.Medium,
    sbs_li_ro: PriceLevel.Medium,
    sbs_li_ru: PriceLevel.Medium,
    sbs_li_se: PriceLevel.Medium,
    sbs_li_ua: PriceLevel.Medium,
    sbs_li_uk: PriceLevel.Medium,
    sbs_li_us: PriceLevel.Medium,
    sbs_li_vn: PriceLevel.Medium,

    sbs_pa_ar: PriceLevel.Medium,
    sbs_pa_br: PriceLevel.Medium,
    sbs_pa_de: PriceLevel.Medium,
    sbs_pa_es: PriceLevel.Medium,
    sbs_pa_fr: PriceLevel.Medium,
    sbs_pa_id: PriceLevel.Medium,
    sbs_pa_ie: PriceLevel.Medium,
    sbs_pa_ke: PriceLevel.Medium,
    sbs_pa_kg: PriceLevel.Medium,
    sbs_pa_kh: PriceLevel.Medium,
    sbs_pa_la: PriceLevel.Medium,
    sbs_pa_lt: PriceLevel.Medium,
    sbs_pa_lv: PriceLevel.Medium,
    sbs_pa_nl: PriceLevel.Medium,
    sbs_pa_pl: PriceLevel.Medium,
    sbs_pa_ro: PriceLevel.Medium,
    sbs_pa_ru: PriceLevel.Medium,
    sbs_pa_se: PriceLevel.Medium,
    sbs_pa_ua: PriceLevel.Medium,
    sbs_pa_uk: PriceLevel.Medium,
    sbs_pa_us: PriceLevel.Medium,
    sbs_pa_vn: PriceLevel.Medium,

    sbs_si_ar: PriceLevel.Medium,
    sbs_si_br: PriceLevel.Medium,
    sbs_si_de: PriceLevel.Medium,
    sbs_si_es: PriceLevel.Medium,
    sbs_si_fr: PriceLevel.Medium,
    sbs_si_id: PriceLevel.Medium,
    sbs_si_ie: PriceLevel.Medium,
    sbs_si_ke: PriceLevel.Medium,
    sbs_si_kg: PriceLevel.Medium,
    sbs_si_kh: PriceLevel.Medium,
    sbs_si_la: PriceLevel.Medium,
    sbs_si_lt: PriceLevel.Medium,
    sbs_si_lv: PriceLevel.Medium,
    sbs_si_nl: PriceLevel.Medium,
    sbs_si_pl: PriceLevel.Medium,
    sbs_si_ro: PriceLevel.Medium,
    sbs_si_ru: PriceLevel.Medium,
    sbs_si_se: PriceLevel.Medium,
    sbs_si_ua: PriceLevel.Medium,
    sbs_si_uk: PriceLevel.Medium,
    sbs_si_us: PriceLevel.Medium,
    sbs_si_vn: PriceLevel.Medium,

    sbs_sc_ar: PriceLevel.Medium,
    sbs_sc_br: PriceLevel.Medium,
    sbs_sc_de: PriceLevel.Medium,
    sbs_sc_es: PriceLevel.Medium,
    sbs_sc_fr: PriceLevel.Medium,
    sbs_sc_id: PriceLevel.Medium,
    sbs_sc_ie: PriceLevel.Medium,
    sbs_sc_ke: PriceLevel.Medium,
    sbs_sc_kg: PriceLevel.Medium,
    sbs_sc_kh: PriceLevel.Medium,
    sbs_sc_la: PriceLevel.Medium,
    sbs_sc_lt: PriceLevel.Medium,
    sbs_sc_lv: PriceLevel.Medium,
    sbs_sc_nl: PriceLevel.Medium,
    sbs_sc_pl: PriceLevel.Medium,
    sbs_sc_ro: PriceLevel.Medium,
    sbs_sc_ru: PriceLevel.Medium,
    sbs_sc_se: PriceLevel.Medium,
    sbs_sc_ua: PriceLevel.Medium,
    sbs_sc_uk: PriceLevel.Medium,
    sbs_sc_us: PriceLevel.Medium,
    sbs_sc_vn: PriceLevel.Medium,

    sbs_ub_ar: PriceLevel.Medium,
    sbs_ub_br: PriceLevel.Medium,
    sbs_ub_de: PriceLevel.Medium,
    sbs_ub_es: PriceLevel.Medium,
    sbs_ub_fr: PriceLevel.Medium,
    sbs_ub_id: PriceLevel.Medium,
    sbs_ub_ie: PriceLevel.Medium,
    sbs_ub_ke: PriceLevel.Medium,
    sbs_ub_kg: PriceLevel.Medium,
    sbs_ub_kh: PriceLevel.Medium,
    sbs_ub_la: PriceLevel.Medium,
    sbs_ub_lt: PriceLevel.Medium,
    sbs_ub_lv: PriceLevel.Medium,
    sbs_ub_nl: PriceLevel.Medium,
    sbs_ub_pl: PriceLevel.Medium,
    sbs_ub_ro: PriceLevel.Medium,
    sbs_ub_ru: PriceLevel.Medium,
    sbs_ub_se: PriceLevel.Medium,
    sbs_ub_ua: PriceLevel.Medium,
    sbs_ub_uk: PriceLevel.Medium,
    sbs_ub_us: PriceLevel.Medium,
    sbs_ub_vn: PriceLevel.Medium,

    sbs_vi_ar: PriceLevel.Medium,
    sbs_vi_br: PriceLevel.Medium,
    sbs_vi_de: PriceLevel.Medium,
    sbs_vi_es: PriceLevel.Medium,
    sbs_vi_fr: PriceLevel.Medium,
    sbs_vi_id: PriceLevel.Medium,
    sbs_vi_ie: PriceLevel.Medium,
    sbs_vi_ke: PriceLevel.Medium,
    sbs_vi_kg: PriceLevel.Medium,
    sbs_vi_kh: PriceLevel.Medium,
    sbs_vi_la: PriceLevel.Medium,
    sbs_vi_lt: PriceLevel.Medium,
    sbs_vi_lv: PriceLevel.Medium,
    sbs_vi_nl: PriceLevel.Medium,
    sbs_vi_pl: PriceLevel.Medium,
    sbs_vi_ro: PriceLevel.Medium,
    sbs_vi_ru: PriceLevel.Medium,
    sbs_vi_se: PriceLevel.Medium,
    sbs_vi_ua: PriceLevel.Medium,
    sbs_vi_uk: PriceLevel.Medium,
    sbs_vi_us: PriceLevel.High,
    sbs_vi_vn: PriceLevel.Medium,

    sbs_ya_ar: PriceLevel.Medium,
    sbs_ya_br: PriceLevel.Medium,
    sbs_ya_de: PriceLevel.Medium,
    sbs_ya_es: PriceLevel.Medium,
    sbs_ya_fr: PriceLevel.Medium,
    sbs_ya_id: PriceLevel.Medium,
    sbs_ya_ie: PriceLevel.Medium,
    sbs_ya_ke: PriceLevel.Medium,
    sbs_ya_kg: PriceLevel.Medium,
    sbs_ya_kh: PriceLevel.Medium,
    sbs_ya_la: PriceLevel.Medium,
    sbs_ya_lt: PriceLevel.Medium,
    sbs_ya_lv: PriceLevel.Medium,
    sbs_ya_nl: PriceLevel.Medium,
    sbs_ya_pl: PriceLevel.Medium,
    sbs_ya_ro: PriceLevel.Medium,
    sbs_ya_ru: PriceLevel.Medium,
    sbs_ya_se: PriceLevel.Medium,
    sbs_ya_ua: PriceLevel.Medium,
    sbs_ya_uk: PriceLevel.Medium,
    sbs_ya_us: PriceLevel.Medium,
    sbs_ya_vn: PriceLevel.Medium,

    sbs_ly_ar: PriceLevel.Medium,
    sbs_ly_br: PriceLevel.Medium,
    sbs_ly_de: PriceLevel.Medium,
    sbs_ly_es: PriceLevel.Medium,
    sbs_ly_fr: PriceLevel.Medium,
    sbs_ly_id: PriceLevel.Medium,
    sbs_ly_ie: PriceLevel.Medium,
    sbs_ly_ke: PriceLevel.Medium,
    sbs_ly_kg: PriceLevel.Medium,
    sbs_ly_kh: PriceLevel.Medium,
    sbs_ly_la: PriceLevel.Medium,
    sbs_ly_lt: PriceLevel.Medium,
    sbs_ly_lv: PriceLevel.Medium,
    sbs_ly_nl: PriceLevel.Medium,
    sbs_ly_pl: PriceLevel.Medium,
    sbs_ly_ro: PriceLevel.Medium,
    sbs_ly_ru: PriceLevel.Medium,
    sbs_ly_se: PriceLevel.Medium,
    sbs_ly_ua: PriceLevel.Medium,
    sbs_ly_uk: PriceLevel.Medium,
    sbs_ly_us: PriceLevel.Medium,
    sbs_ly_vn: PriceLevel.Medium,

    sbs_ta_ar: PriceLevel.Medium,
    sbs_ta_br: PriceLevel.Medium,
    sbs_ta_de: PriceLevel.Medium,
    sbs_ta_es: PriceLevel.Medium,
    sbs_ta_fr: PriceLevel.Medium,
    sbs_ta_id: PriceLevel.Medium,
    sbs_ta_ie: PriceLevel.Medium,
    sbs_ta_ke: PriceLevel.Medium,
    sbs_ta_kg: PriceLevel.Medium,
    sbs_ta_kh: PriceLevel.Medium,
    sbs_ta_la: PriceLevel.Medium,
    sbs_ta_lt: PriceLevel.Medium,
    sbs_ta_lv: PriceLevel.Medium,
    sbs_ta_nl: PriceLevel.Medium,
    sbs_ta_pl: PriceLevel.Medium,
    sbs_ta_ro: PriceLevel.Medium,
    sbs_ta_ru: PriceLevel.Medium,
    sbs_ta_se: PriceLevel.Medium,
    sbs_ta_ua: PriceLevel.Medium,
    sbs_ta_uk: PriceLevel.Medium,
    sbs_ta_us: PriceLevel.Medium,
    sbs_ta_vn: PriceLevel.Medium,

    sbs_tt_ar: PriceLevel.Medium,
    sbs_tt_br: PriceLevel.Medium,
    sbs_tt_de: PriceLevel.Medium,
    sbs_tt_es: PriceLevel.Medium,
    sbs_tt_fr: PriceLevel.Medium,
    sbs_tt_id: PriceLevel.Medium,
    sbs_tt_ie: PriceLevel.Medium,
    sbs_tt_ke: PriceLevel.Medium,
    sbs_tt_kg: PriceLevel.Medium,
    sbs_tt_kh: PriceLevel.Medium,
    sbs_tt_la: PriceLevel.Medium,
    sbs_tt_lt: PriceLevel.Medium,
    sbs_tt_lv: PriceLevel.Medium,
    sbs_tt_nl: PriceLevel.Medium,
    sbs_tt_pl: PriceLevel.Medium,
    sbs_tt_ro: PriceLevel.Medium,
    sbs_tt_ru: PriceLevel.Medium,
    sbs_tt_se: PriceLevel.Medium,
    sbs_tt_ua: PriceLevel.Medium,
    sbs_tt_uk: PriceLevel.Medium,
    sbs_tt_us: PriceLevel.Medium,
    sbs_tt_vn: PriceLevel.Medium,

    sbs_ve_ar: PriceLevel.Medium,
    sbs_ve_br: PriceLevel.Medium,
    sbs_ve_de: PriceLevel.Medium,
    sbs_ve_es: PriceLevel.Medium,
    sbs_ve_fr: PriceLevel.Medium,
    sbs_ve_id: PriceLevel.Medium,
    sbs_ve_ie: PriceLevel.Medium,
    sbs_ve_ke: PriceLevel.Medium,
    sbs_ve_kg: PriceLevel.Medium,
    sbs_ve_kh: PriceLevel.Medium,
    sbs_ve_la: PriceLevel.Medium,
    sbs_ve_lt: PriceLevel.Medium,
    sbs_ve_lv: PriceLevel.Medium,
    sbs_ve_nl: PriceLevel.Medium,
    sbs_ve_pl: PriceLevel.Medium,
    sbs_ve_ro: PriceLevel.Medium,
    sbs_ve_ru: PriceLevel.Medium,
    sbs_ve_se: PriceLevel.Medium,
    sbs_ve_ua: PriceLevel.Medium,
    sbs_ve_uk: PriceLevel.Medium,
    sbs_ve_us: PriceLevel.Medium,
    sbs_ve_vn: PriceLevel.Medium,
}
