import { observable, action, makeAutoObservable, runInAction } from 'mobx';

export class DeviceStore {
    @observable carrier: string = '';
    @observable brand: string = '';
    @observable deviceName: string = '';
    @observable model: string = '';
    @observable os: string = '';
    @observable osVersion: string = '';
    @observable version: string = '';
    @observable applicationId: string = '';
    @observable deviceId: string = '';
    @observable timeZone: string = '';
    @observable languageCode: string = '';
    @observable countryCode: string = '';

    constructor() {
        makeAutoObservable(this, {
            carrier: observable,
            brand: observable,
            deviceName: observable,
            model: observable,
            os: observable,
            osVersion: observable,
            version: observable,
            applicationId: observable,
            deviceId: observable,
            timeZone: observable,
            languageCode: observable,
            countryCode: observable,
            setInfo: action,
        });
    }

    @action async setInfo({ carrier, brand, deviceName, model, os, osVersion, version, applicationId, deviceId, timeZone, languageCode, countryCode }: {}) {
        runInAction(() => {
            this.carrier = carrier;
            this.brand = brand;
            this.deviceName = deviceName;
            this.model = model;
            this.os = os;
            this.osVersion = osVersion;
            this.version = version;
            this.applicationId = applicationId;
            this.deviceId = deviceId;
            this.timeZone = timeZone;
            this.languageCode = languageCode;
            this.countryCode = countryCode;
        });

        return {
            carrier,
            brand,
            deviceName,
            model,
            os,
            osVersion,
            version,
            applicationId,
            deviceId,
            timeZone,
            languageCode,
            countryCode,
        };
    }
}

export default new DeviceStore();