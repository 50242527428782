import moment from 'moment';
import * as Font from 'expo-font';
import * as Crypto from 'expo-crypto';
import * as Device from 'expo-device';
import { Platform } from 'react-native';
import PackageJson from './package.json';
import * as Cellular from 'expo-cellular';
import { db } from './src/config/firebase';
import { StatusBar } from 'expo-status-bar';
import i18n, { t } from './src/services/i18n';
import Purchases from 'react-native-purchases';
import { useKeepAwake } from 'expo-keep-awake';
import { getLocales } from 'expo-localization';
import * as Application from 'expo-application';
import * as SecureStore from 'expo-secure-store';
import * as SplashScreen from 'expo-splash-screen';
import { detectIncognito } from "detectincognitojs";
import { StoreProvider, rootStore } from './src/store';
import { collection, doc, setDoc } from 'firebase/firestore';
import { NavigationContainer } from '@react-navigation/native';
import { Fragment, useCallback, useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';


SplashScreen.preventAutoHideAsync();

// Screens
import Home from './src/screens/Home';
import Numbers from './src/screens/Numbers';
import Purchase from './src/screens/Purchase';
import ServiceDetail from './src/screens/ServiceDetail';

// Settings Screens
import Settings from './src/screens/Settings';
import Faq from './src/screens/Settings/Faq';
import Policy from './src/screens/Settings/Policy';
import AboutUs from './src/screens/Settings/AboutUs';
import TextArea from './src/components/Settings/TextArea';

i18n.init();
const Stack = createNativeStackNavigator();
const linking = {
  prefixes: [
    "exp://",
    "localhost:19006",
    "http://localhost:19006",
    "https://www.i-verify.app",
    "https://i-verify.app",
  ],
  config: {
    screens: {
      "home": "",
      "settings": "settings",
      "numbers": "numbers",
      "policy": "policy/:type",
      "faq": "faq",
      "about-us": "about-us",
      "report-a-problem": "report-a-problem",
      "service-detail": "service-detail/:id",

      "error": "error",
      "legal": "legal",
      "terms": "terms",
    },
  }
};

function App(): JSX.Element {
  useKeepAwake();
  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {

    async function getDeviceIdForWeb() {
      let newUser = false;
      let deviceId = await AsyncStorage.getItem('deviceId');
      if (!deviceId) {
        newUser = true;
        const newDeviceId = Crypto.randomUUID().toUpperCase();
        await AsyncStorage.setItem('deviceId', newDeviceId);
        deviceId = newDeviceId;
      }
      return { deviceId, newUser };
    }

    async function getDeviceId() {
      let newUser = false;
      let deviceId = await SecureStore.getItemAsync('deviceId');
      if (!deviceId) {
        newUser = true;
        const newDeviceId = Platform.select({
          ios: Crypto.randomUUID(),
          android: Application.androidId
        });
        await SecureStore.setItemAsync('deviceId', newDeviceId);
        deviceId = newDeviceId;
      }
      return { deviceId, newUser };
    }

    async function saveUser(user: any) {
      const locales = getLocales();
      const docRef = await setDoc(doc(db, `users/${user.deviceId}`),
        {
          bundleId: user.applicationId,
          brand: user.brand,
          carrier: user.carrier,
          deviceName: user.deviceName,
          id: user.deviceId,
          model: user.model,
          os: user.os,
          osVersion: user.osVersion,
          version: user.version,
          createdAt: Date.now(),
          timeZone: user.timeZone,
          languageCode: user.languageCode,
          countryCode: user.countryCode,
          notificationToken: null,
          purchases: [],
        }).catch((error) => {
          console.error("Error adding document: ", error);
        });
    }

    async function prepare() {
      try {
        await Font.loadAsync({
          'SFProDisplay-Bold': require('./src/assets/fonts/SFProDisplay-Bold.otf'),
          'SFProDisplay-Medium': require('./src/assets/fonts/SFProDisplay-Medium.otf'),
          'SFProDisplay-Regular': require('./src/assets/fonts/SFProDisplay-Regular.otf')
        });

        let deviceId, newUser;

        if (Platform.OS == 'web') {
          ({ deviceId, newUser } = await getDeviceIdForWeb());
        } else {
          ({ deviceId, newUser } = await getDeviceId());
        }

        const carrier = await Cellular.getCarrierNameAsync();
        let brand = Device.brand;
        const deviceName = Device.deviceName;
        const model = Device.modelName;
        const os = Device.osName;
        const osVersion = Device.osVersion;
        let version = Application.nativeApplicationVersion;
        let applicationId = Application.applicationId;
        const timeZone = (moment().utcOffset() / 60).toString();
        const languageCode = getLocales()[0].languageCode;
        const countryCode = getLocales()[0].regionCode;

        if (Platform.OS == 'web') {
          applicationId = 'web.i-verify.app';
          version = PackageJson.version;
          if (navigator.userAgent.match(/chrome|chromium|crios/i)) {
            brand = "chrome";
          } else if (navigator.userAgent.match(/firefox|fxios/i)) {
            brand = "firefox";
          } else if (navigator.userAgent.match(/safari/i)) {
            brand = "safari";
          } else if (navigator.userAgent.match(/opr\//i)) {
            brand = "opera";
          } else if (navigator.userAgent.match(/edg/i)) {
            brand = "edge";
          } else {
            brand = "unknown";
          }
        }

        const user = await rootStore.deviceStore.setInfo({
          carrier,
          brand,
          deviceName,
          model,
          os,
          osVersion,
          version,
          applicationId,
          deviceId,
          timeZone,
          languageCode,
          countryCode
        });

        if (newUser) saveUser(user as any);

        const apiKey = Platform.select({
          ios: 'appl_cCNorlrKIChQeuhhDNYMPfQSQkf',
          android: 'goog_KFVaWLZnENEijZyWzsVjSFXreKx'
        });

        if (Platform.OS != 'web') {
          Purchases.configure({ apiKey: apiKey, appUserID: deviceId });
          Purchases.setAttributes({
            carrier,
            brand,
            deviceName,
            model,
            os,
            osVersion,
            version,
            applicationId,
            deviceId,
            timeZone,
            languageCode,
            countryCode
          });
        }
      } catch (e) {
        console.warn(e);
      } finally {
        if (Platform.OS == 'web') {
          const incognito = await detectIncognito();
          if (incognito.isPrivate) {
            alert(t('common:errors:incognito'));
          }
          else {
            setAppIsReady(true);
          }
        }
        else {
          setAppIsReady(true);
        }
      }
    }

    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!appIsReady) {
    return null;
  }

  return (
    <StoreProvider>
      <SafeAreaProvider onLayout={onLayoutRootView}>
        <Fragment>
          {/* <SafeAreaView edges={["top"]} style={{ flex: 0, backgroundColor: "#f8f9fc" }} /> */}
          <SafeAreaView edges={["left", "right", "bottom"]} style={{ flex: 1, backgroundColor: "#f8f9fc", position: "relative" }} >
            <StatusBar style="dark" />
            <NavigationContainer linking={linking} independent={true}>
              <Stack.Navigator screenOptions={{
                headerShown: false,
              }}>
                <Stack.Screen name="home" options={{ title: "iVerify" }} component={Home} />
                <Stack.Screen name="numbers" options={{ title: t('numbers:header:title') }} component={Numbers} />
                <Stack.Screen name="service-detail" options={{ title: "iVerify" }} component={ServiceDetail} />
                <Stack.Screen name="purchase" options={{ title: "iVerify" }} component={Purchase} />
                <Stack.Screen name="settings" options={{ title: t('settings:header:title') }} component={Settings} />
                <Stack.Screen name="policy" options={{ title: t('settings:sections:general:privacy_policy') }} component={Policy} />
                <Stack.Screen name="faq" options={{ title: t('settings:sections:support:faq') }} component={Faq} />
                <Stack.Screen name="about-us" options={{ title: t('settings:sections:general:about-us') }} component={AboutUs} />
                <Stack.Screen name="report-a-problem" options={{ title: t('report-a-problem:alert:title') }} component={TextArea} options={{ animationEnabled: true, presentation: "modal", headerShown: false }} />
              </Stack.Navigator>
            </NavigationContainer>
          </SafeAreaView>
        </Fragment>
      </SafeAreaProvider>
    </StoreProvider>
  );
}

export default App;
