export const fallback = "en";

export const supportedLocales = {
    en: {
        name: "English",
        translationFileLoader: () => require('../lang/en.json'),
        momentLocaleLoader: () => Promise.resolve(),
    },
    tr: {
        name: "Türkçe",
        translationFileLoader: () => require('../lang/tr.json'),
        momentLocaleLoader: () => import('moment/locale/tr'),
    },
};

export const defaultNamespace = "common";

export const namespaces = [
    "faq",
    "home",
    "common",
    "policy",
    "numbers",
    "purchase",
    "settings",
    "about-us",
    "revenuecat",
    "service-detail",
    "report-a-problem"
];