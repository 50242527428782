import { t } from '../../services/i18n';
import RenderHtml from 'react-native-render-html';
import Header from '../../components/Header/Settings';
import React, { useState, useEffect, useRef } from 'react';
import { staticContent } from '../../assets/data/StaticContent';
import { Text, View, StyleSheet, Dimensions, ScrollView, Image } from 'react-native';

interface AboutUsProps {
    navigation: any;
    route: any;
}

const AboutUs = (props: AboutUsProps) => {
    const [data, setData] = useState<any>(staticContent.aboutUs);

    return (
        <>
            <Header returnPage='settings' route={props.route} navigation={props.navigation} title={t('about-us:header:title')} description={t('about-us:header:subtitle')} />
            <View style={{ flex: 1 }}>
                <View style={styles.imageContainer}>
                    <Image source={require('../../assets/images/logo.webp')} style={styles.image} />
                </View>
                <View style={styles.container}>
                    <ScrollView style={styles.contentArea} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
                        <RenderHtml
                            source={{ html: data }}
                            contentWidth={Dimensions.get('window').width}
                            tagsStyles={{ p: { color: "#666666" } }}
                        />
                    </ScrollView>
                </View>
            </View>
        </>
    );
};

export default AboutUs;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        width: "100%",
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f8f9fc',
    },
    imageContainer: {
        // flex: 0,
        height: 150,
        width: "100%",
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#5856d7',
    },
    image: {
        width: 100,
        height: 100,
        marginTop: -16,
        tintColor: '#ffffff',
        resizeMode: 'contain',
    },
    contentArea: {
        flex: 1,
        padding: 16,
        width: "100%",
        marginTop: -60,
        borderRadius: 10,
        backgroundColor: '#ffffff',
    },
});
